import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'reducers/store'
import { useParams } from 'react-router'
import { fetchPrimeServiceProjects } from 'reducers/projectPrimeServices/actions'
import {
  ISearchCondition,
  IPrimeServiceProjectState,
} from 'reducers/projectPrimeServices/types'
import { IPrimeContractCountsState } from 'reducers/contractsCountServices/types'
import { Toolbar } from './Toolbar/ToolBar'
import { Footer } from './Footer/Footer'
import { Table } from './Table/Table'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { changeProjectTab } from 'reducers/projectPrimeServices/actions'
import CircularProgressFader from 'view/components/CircularProgressFader'
import { fetchPrimeContractCounts } from 'reducers/contractsCountServices/actions'
import { NoDataInBox } from 'view/components/form/NoDataInBox'
import { HistoryBackButton } from 'view/components/button'
import 'react-tabs/style/react-tabs.css'
import { useAuth } from 'hooks/useAuth'
import {
  UnauthorizedErrorStatus,
  ForbiddenErrorStatus,
  NotFoundErrorStatus,
} from '../../../constants'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: 1000,
  },
}))
const tabStyle = { width: '150px' }
const tabPanelStyle = {
  borderLeft: 'solid 1px #aaa',
  borderRight: 'solid 1px #aaa',
  borderBottom: 'solid 1px #aaa',
}
const firstLoadingStyle = {
  height: 463,
  marginTop: '40px',
}

interface IResultParam {
  visibleNormalTab: boolean
  visibleLiteTab: boolean
}

/**
 * 使用する検索条件を取得する
 * @param state
 */
export const projectSearchConditions = (
  state: IPrimeServiceProjectState,
): ISearchCondition => {
  return state.isGsLite ? state.liteSearchCondition : state.searchCondition
}

/**
 * 出力するタブを上限数と選択数から判断する
 */
export const appearStatus = (
  state: IPrimeContractCountsState,
): IResultParam => {
  // 通常プロジェクトを出すか
  const visibleNormalTab = !(
    state.normalProjects.primeLimitCount === 0 &&
    state.normalProjects.primeSelectCount === 0
  )
  // liteプロジェクトを出すか
  const visibleLiteTab = !(
    state.liteProjects.primeLimitCount === 0 &&
    state.liteProjects.primeSelectCount === 0
  )

  return { visibleNormalTab, visibleLiteTab }
}

export const PrimeProjectsPage = (): JSX.Element => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const contractState = useSelector(
    (state: AppState) => state.primeContractCounts,
  )
  const { companyId } = useParams<{ companyId: string }>()
  const { isManager } = useAuth()

  const handleSelect = () => {
    dispatch(changeProjectTab())
  }

  const condition: ISearchCondition = projectSearchConditions(state)
  const { visibleNormalTab, visibleLiteTab } = appearStatus(contractState)
  const isGsLite = !visibleNormalTab || state.isGsLite
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(
      fetchPrimeServiceProjects({
        companyId,
        page: state.page,
        perPage: state.perPage,
        isGsLite,
        pageTokens: state.pageTokens,
        condition,
      }),
    )
    // タブを出すか出さないかの判断のため、プロジェクト上限数をどちらも取得する
    dispatch(fetchPrimeContractCounts(companyId, true))
    dispatch(fetchPrimeContractCounts(companyId, false))
    // conditionを含めると検索の都度実行してしまうため
    // eslint-disable-next-line
  }, [dispatch, companyId, isGsLite])

  useEffect(() => {
    if (
      state.api.method === 'GET' &&
      state.api.status !== 200 &&
      state.api.status !== undefined
    ) {
      if (state.api.status === UnauthorizedErrorStatus) {
        history.push('/auth')
      }
      if (
        [ForbiddenErrorStatus, NotFoundErrorStatus].includes(state.api.status)
      ) {
        history.push('/404')
      }
    }
    // エラーメッセージはここで出す
    if (state.api.status !== 200 && state.api.error?.response.data.message) {
      const message = state.api.error?.response.data.message
      enqueueSnackbar(message, { variant: 'error' })
    }
  }, [state.api, enqueueSnackbar, history])

  const loading = state.api.isFetching || contractState.isFetching
  // 初期表示かどうか
  const firstLoading =
    contractState.normalProjects.primeLimitCount === null ||
    contractState.liteProjects.primeLimitCount === null
  if (firstLoading) {
    return (
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <div style={firstLoadingStyle}>
            <CircularProgressFader loading={loading} />
          </div>
        </Paper>
      </div>
    )
  }

  // どちらも0件の場合はタブ自体を出さない
  if (!visibleNormalTab && !visibleLiteTab) {
    return (
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <div style={firstLoadingStyle}>
            <NoDataInBox />
            {isManager && (
              <div>
                <br /> <HistoryBackButton />
              </div>
            )}
          </div>
        </Paper>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Tabs
          style={{ marginBottom: '0px' }}
          onSelect={handleSelect}
          selectedIndex={visibleNormalTab ? (state.isGsLite ? 1 : 0) : 1}
        >
          <TabList style={{ textAlign: 'left', marginBottom: '0px' }}>
            <Tab
              style={
                visibleNormalTab
                  ? state.api.isUpdating
                    ? { ...tabStyle, pointerEvents: 'none' }
                    : { ...tabStyle }
                  : { ...tabStyle, display: 'none' }
              }
            >
              <Typography align="center" style={{ fontSize: 15 }}>
                グリーンサイト
              </Typography>
            </Tab>
            <Tab
              style={
                visibleLiteTab
                  ? state.api.isUpdating
                    ? { ...tabStyle, pointerEvents: 'none' }
                    : { ...tabStyle }
                  : { ...tabStyle, display: 'none' }
              }
            >
              <Typography align="center" style={{ fontSize: 15 }}>
                グリーンサイトLite
              </Typography>
            </Tab>
          </TabList>
          <TabPanel
            style={
              visibleNormalTab
                ? { ...tabPanelStyle }
                : { ...tabPanelStyle, display: 'none' }
            }
          >
            <Toolbar />
            <Table />
          </TabPanel>
          <TabPanel
            style={
              visibleLiteTab
                ? { ...tabPanelStyle }
                : { ...tabPanelStyle, display: 'none' }
            }
          >
            <Toolbar />
            <Table />
          </TabPanel>
        </Tabs>
        <Footer />
      </Paper>
    </div>
  )
}
