import Repository from './repository'
import { AxiosResponse } from 'axios'

export interface IBranches {
  /** 支店名 */
  branchName: string
}

export default {
  async get(companyId: string): Promise<AxiosResponse<IBranches[]>> {
    return Repository.get<IBranches[]>(`/companies/${companyId}/branches`)
  },
}
