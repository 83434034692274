import React from 'react'
import InboxIcon from '@material-ui/icons/Inbox'
import { Typography } from '@material-ui/core'

export const NoDataInBox = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'center' }}>
      <InboxIcon fontSize="large" color="disabled" />
      <div>
        <Typography variant="body2" component="span">
          データが見つかりませんでした。
        </Typography>
      </div>
    </div>
  )
}
