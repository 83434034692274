import React from 'react'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Routes } from 'Routes'
import { palette } from 'theme'
import { SnackbarProvider } from 'notistack'

const App = (): JSX.Element => {
  const theme = createMuiTheme({ palette })

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </SnackbarProvider>
  )
}

export default App
