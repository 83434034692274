export const authResponse = {
  authorizationUrl:
    'https://t5.sso.auth.kensetsu-site.com:443/sso/oauth2/authorize?client_id=invoice&scope=openidprofile&response_type=code&redirect_uri=https%3A%2F%2Ft1-api.invoice.mcdppf.com%2Fauth%2Fcallback&code_challenge=XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX&code_challenge_method=RS256',
}

export const authResponseHeader = {
  'Set-Cookie': 'codeVerifier=__MOCK__xxxxxxxxxxxxxxxxxxxxxx',
}

export const clearCodeVerifierResponseHeader = {
  'Set-Cookie': 'codeVerifier=; Max-Age=0; Path=/;',
}

export const clearLoginUserInfoResponseHeader = {
  'Set-Cookie': 'loginUserInfo=; Max-Age=0; Path=/;',
}

export const authCallbackResponse = {
  mcdpId: '00000001',
  lastName: '田中',
  firstName: '太郎',
  companyId: '0000000000',
  companyName: '田中コーポレーション',
  managerType: '6',
}

export const authCallbackResponseHeader = {
  // セッション情報などが保持された暗号化されたユーザ情報をクライアントで常に持つことで、サーバサイドでセッション管理を不要にしている
  'Set-Cookie': 'loginUserInfo=MOCK0000',
}
