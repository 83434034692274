import Repository from './repository'
import { AxiosResponse } from 'axios'
import { ISearchParams } from 'view/pages/companySelects/SearchForms'

export interface ICompanySelects {
  /** 取得件数 */
  totalCount: number
  /** 元請企業取得結果 */
  primeCompanies: ICompany[]
}

export interface ICompany {
  companyId: string
  companyName: string
}

// クエリストリングが確定後要追記
interface IParams {
  companyId?: string
  companyName?: string
}

export default {
  async get(): Promise<AxiosResponse<ICompanySelects>> {
    return Repository.get<ICompanySelects>(`/companies`)
  },

  async getMany(
    searchCondition: ISearchParams,
  ): Promise<AxiosResponse<ICompanySelects>> {
    const params: IParams = {}
    if (searchCondition.companyId !== '') {
      params.companyId = searchCondition.companyId
    }
    if (searchCondition.companyName !== '') {
      params.companyName = searchCondition.companyName
    }

    return Repository.get<ICompanySelects>(`/companies`, {
      params,
    })
  },
}
