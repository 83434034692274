/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { initialize } from 'reducers/projectPrimeServices/actions'
import store from 'reducers/store'

export const onRequestConfig = (request: AxiosRequestConfig) => {
  return request
}
export const onRequestError: ((error: any) => any) | undefined = (error) => {
  return Promise.reject(error)
}
export const onResponseConfig = (response: AxiosResponse) => {
  if (response.status === 200) {
    document.cookie = response.headers['Set-Cookie']
  }

  return response
}
export const onResponseError = (error: any) => {
  if ([403, 404].includes(error.response.status)) {
    store.dispatch(initialize())
  }

  return Promise.reject(error)
}
