/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios'
import mock from './mock/mock.router'
import axiosRetry from 'axios-retry'

import {
  onRequestConfig,
  onRequestError,
  onResponseConfig,
  onResponseError,
} from './axiosIntecepter'
import getLogger from 'logger'

const logger = getLogger('@repository')

const baseURL = process.env.REACT_APP_PRIME_SERVICE_API_URL
const apiKey = process.env.REACT_APP_PROJECT_PORTAL_BACKEND_API_KEY

const client = axios.create({
  baseURL,
  headers: { 'x-api-key': apiKey },
  withCredentials: true,
})
// API Call前共通処理（トークンセット）
client.interceptors.request.use(onRequestConfig, onRequestError)
// API Call後共通処理
client.interceptors.response.use(onResponseConfig, onResponseError)

// Exponential back-off retry delay between requests
axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  // 何らかの要因によりリトライが発生した場合、ログを出力しておく
  // axios-retryには message/handler の仕組みはないので
  // retryConditionに無理やりロガーを仕込んで、必ず true を返すようにしている。
  // https://github.com/softonic/axios-retry/issues/134
  retryCondition: (err: AxiosError) => {
    logger.error(err)

    if (err.response) {
      if (err.response?.status >= 500) {
        return true
      }
    }

    return false
  },
})
const isBoolean = (str?: string) => str === 'true'

// 開発環境では、実際に起動する画面でもモックデータを表示したい。
// そのため、jestで mock function を 注入してテストするのではなく、
// 環境に応じてWebAPIエンドポイントを見るか、モックを見るか切り替えている。

if (isBoolean(process.env.REACT_APP_USE_MOCK)) {
  mock.run(client)
}

// IE 11 で Axios が APIリクエストをキャッシュしてしまう問題
// https://keyama.hatenablog.com/entry/2018/11/03/181859
const noCache = (config: any) => {
  if (typeof config.params === 'undefined') {
    config.params = {}
  }
  if (typeof config.params === 'object') {
    if (
      typeof URLSearchParams === 'function' &&
      config.params instanceof URLSearchParams
    )
      config.params.append('_', Date.now().toString())
    else config.params._ = Date.now()
  }

  return config
}

client.interceptors.request.use(noCache)

export default client
