import React from 'react'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { useAuth } from 'hooks/useAuth'

const LogoutButton = (): JSX.Element => {
  const { logout } = useAuth()

  return (
    <Button
      variant="outlined"
      color="inherit"
      onClick={logout}
      startIcon={<CloseIcon />}
      size="small"
    >
      終了(閉じる)
    </Button>
  )
}

export default LogoutButton
