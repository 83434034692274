/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { RowData, CheckableColumnName } from 'view/pages/primeProjects/columns'
import { Checkbox } from '@material-ui/core'
import { usePrimeServiceProjectHook } from '../../../../hooks/usePrimeServiceProject'
import { selectAllFor } from 'reducers/projectPrimeServices/actions'
import { useDispatch } from 'react-redux'

/**
 * チェックボックスを表示する
 * クリックすると指定の列のチェックボックスのチェックON/OFFを全て切り替えるアクションを発火する
 * @param { columnName: カラム名 }
 */
export const CheckBoxHeaderColumn = ({ columnName }: any): JSX.Element => {
  const dispatch = useDispatch()

  const primeServiceProjectState = usePrimeServiceProjectHook()
  const rows = primeServiceProjectState.rows

  /**
   * 指定の列のチェックボックスのチェックが全て ON または OFF であるかを判定する
   * @param columnName
   */
  const hasCheckedAllFor = (columnName: CheckableColumnName): boolean => {
    return rows.every((row: RowData) => row[columnName])
  }

  /**
   * 指定されたカラム（列）のチェックボックスをすべて ON または OFF にする。
   * @param event
   * @param columnName
   */
  const handleSelectAllClickFor = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnName: CheckableColumnName,
  ) => {
    dispatch(
      selectAllFor({
        columnName,
        first: 0,
        last: primeServiceProjectState.perPage,
        checked: event.target.checked,
      }),
    )
  }

  return (
    <Checkbox
      checked={hasCheckedAllFor(columnName)}
      onChange={(event) => handleSelectAllClickFor(event, columnName)}
    />
  )
}
