import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'

interface IProps {
  firstCaption: string
  secondCaption?: string
  firstValue?: number
  secondValue?: number
  unit?: string
  error?: string
  hasError?: boolean
  isFetching?: boolean
}

/**
 * 説明文（caption）がついたカウンターコンポーネント
 * @param
 * ---------
 * 各APIが全てFetchされた後に数値を出す
 * Fetch中は0件を表示
 * @returns 以下のようなViewが表示される
 * 〇〇〇〇〇〇〇〇 <-- firstCaption
 * 〇〇〇〇〇〇〇〇 <-- secondCaption
 * 12 / 324 PJ <-- firstValue / secondValue unit
 * error
 */
export const DescriptionWithValue = (props: IProps): JSX.Element => {
  const {
    firstCaption,
    secondCaption,
    firstValue,
    secondValue,
    unit,
    error,
    hasError,
    isFetching,
  } = props

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid>
        <Typography color="primary" variant="h6">
          {firstCaption}
        </Typography>
      </Grid>
      <Grid>
        <Typography color="primary" variant="h6">
          {secondCaption}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="h4" color={hasError ? 'error' : 'inherit'}>
          {isFetching ? null : firstValue}
          <span style={{ fontSize: 'medium', padding: '5px' }}>/</span>
          {isFetching ? null : secondValue}
          <span style={{ fontSize: 'small' }}> </span>
          <span style={{ fontSize: 'small' }}>{unit}</span>
        </Typography>
      </Grid>
      {hasError ? (
        <Grid>
          {isFetching ? null : (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          )}
        </Grid>
      ) : (
        <div style={{ paddingBottom: '32px' }}></div>
      )}
    </Grid>
  )
}
