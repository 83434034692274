import React from 'react'
import { Typography, Link } from '@material-ui/core'

const Version = () => {
  // バージョン情報としてビルド時に環境変数に git のコミットID の先頭7文字を設定しています。
  return <span>Version: {process.env.REACT_APP_GIT_SHA}</span>
}
export const Copyright = (): JSX.Element => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.mcdata.co.jp/">
        MC Data Plus, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} <Version />
    </Typography>
  )
}
