/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { TextField } from '@material-ui/core'

type TextFieldProps = {
  control: Control<Record<string, any>>
  label: string
  name: string
  defaultValue?: string
}
export const SearchTextField = (props: TextFieldProps): JSX.Element => {
  const { control, label, name, defaultValue } = props

  return (
    <Controller
      as={
        <TextField
          label={label}
          variant="outlined"
          size="small"
          fullWidth={true}
          type="search"
          data-testid={`text-field-${name}`}
        />
      }
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  )
}
