import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Main } from 'view/pages/layout/Main'
import { NotFound } from 'view/pages/404'
import { SystemError } from 'view/pages/500'
import { LogoutPage } from 'view/pages/logout'
import Authorization from 'view/components/sso/Authorization'
import CallBack from 'view/components/sso/CallBack'

/**
 * 認証不要でアクセスできるパスを以下に列挙する
 * 認証が必要なパスは Main コンポーネントの中でさらに分岐させている
 */
export const Routes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact sensitive path="/auth">
          <Authorization />
        </Route>
        <Route exact sensitive path="/auth/callback">
          <CallBack />
        </Route>
        <Route exact sensitive path="/logout">
          <LogoutPage />
        </Route>
        <Route path="/404" component={NotFound} />
        <Route path="/500" component={SystemError} />
        <Route path="/" component={Main} />
      </Switch>
    </BrowserRouter>
  )
}
