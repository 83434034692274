import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { ManagerType } from '../../constants'

const Home = (): JSX.Element => {
  const { user } = useAuth()
  const companyChiefs = [ManagerType.CompanyChief, ManagerType.CompanySubChief]

  return user ? (
    companyChiefs.includes(user.managerType) ? (
      <Redirect to={{ pathname: `/companies/${user.companyId}/services` }} />
    ) : (
      <Redirect to={{ pathname: '/companies' }} />
    )
  ) : (
    <Redirect to={{ pathname: '/auth' }} />
  )
}

export default Home
