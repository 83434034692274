import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { Typography } from '@material-ui/core'

export const OverDataInBox = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'center' }}>
      <WarningIcon fontSize="large" color="primary" />
      <div>
        <Typography variant="body2" component="span" color="primary">
          検索結果が表示上限を超えています。<br></br>
          100件以下となるように検索条件を入力してください。
        </Typography>
      </div>
    </div>
  )
}
