import {
  IPrimeContractCountsAction,
  IPrimeContractCountsActionType as ActionType,
  IPrimeContractCountsState,
} from './types'

const countInitialState = {
  primeLimitCount: null,
  primeSelectCount: null,
  projectRegisteredCount: null,
  projectLimitCount: null,
}

export const initialState = {
  isFetching: false,
  normalProjects: countInitialState,
  /** プライムプロジェクト(lite)契約件数情報 */
  liteProjects: countInitialState,
}

/**
 * 選択企業のプライムプロジェクト契約件数、選択件数を保持する state を更新する reducer
 * dispatch時にコールバックされる
 * @param state
 * @param action
 */
const reducer = (
  state: IPrimeContractCountsState = initialState,
  action: IPrimeContractCountsAction,
): IPrimeContractCountsState => {
  switch (action.type) {
    case ActionType.INITIALIZE:
      return initialState
    case ActionType.FETCH_REQUEST:
      return {
        isFetching: true,
        normalProjects: state.normalProjects,
        liteProjects: state.liteProjects,
      }
    case ActionType.FETCH_SUCCEED:
      return {
        isFetching: false,
        // 取得するタイプによって設定する項目を変更
        normalProjects: action.normalProjects! || state.normalProjects,
        liteProjects: action.liteProjects! || state.liteProjects,
      }
    case ActionType.FETCH_FAILED:
      return {
        isFetching: false,
        normalProjects: state.normalProjects,
        liteProjects: state.liteProjects,
        error: action.error,
      }
    default:
      // 初期化時はここに来る（initialStateのオブジェクトが返却される）
      return state
  }
}

export default reducer
