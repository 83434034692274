/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  NO_DATA_ERROR,
  UNAUTHORIZED_ERROR,
  INTERNAL_SERVER_ERROR,
} from './errors'

const database = [
  {
    companyId: '0000000000',
    companyName: 'テスト用元請　淡路建設',
    updateDatetime: '2020-04-22 09:00:00.000000',
    primeProjects: [
      {
        branchId: '0001',
        branchName: '東陽町支店',
        projectId: '0000000001',
        projectName: '東陽町ネクストビル建設プロジェクト',
        projectInsertDatetime: '2020-04-21 09:00:00.000000',
        serviceId: '0000110',
        serviceName: 'ワークサイト',
        enabled: true,
      },
      {
        branchId: '0001',
        branchName: '東陽町支店',
        projectId: '0000000001',
        projectName: '東陽町ネクストビル建設プロジェクト',
        projectInsertDatetime: '2020-04-22 09:00:00.000000',
        serviceId: '0000130',
        serviceName: 'スキルマップサイト',
        enabled: false,
      },
      {
        branchId: '0001',
        branchName: '恵比寿支店',
        projectId: '0000000002',
        projectName: '恵比寿ビル工事',
        projectInsertDatetime: '2020-04-20 09:00:00.000000',
        serviceId: '0000110',
        serviceName: 'ワークサイト',
        enabled: true,
      },
      {
        branchId: '0001',
        branchName: '恵比寿支店',
        projectId: '0000000002',
        projectName: '恵比寿ビル工事',
        projectInsertDatetime: '2020-04-23 09:00:00.000000',
        serviceId: '0000130',
        serviceName: 'スキルマップサイト',
        enabled: true,
      },
      // 0000000003
      {
        branchId: '0002',
        branchName: '荻窪支店',
        projectId: '0000000003',
        projectName:
          '東京新都心（NEW）新荻窪ベルグプレシャデス田町リニューアル建設プロジェクト（仮名）長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い',
        projectInsertDatetime: '2020-04-23 09:00:00.000000',
        serviceId: '0000110',
        serviceName: 'ワークサイト',
        enabled: true,
      },
      {
        branchId: '0002',
        branchName: '荻窪支店',
        projectId: '0000000003',
        projectName:
          '東京新都心（NEW）新荻窪ベルグプレシャデス田町リニューアル建設プロジェクト（仮名）長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い',
        projectInsertDatetime: '2020-04-23 09:00:00.000000',
        serviceId: '0000130',
        serviceName: 'スキルマップサイト',
        enabled: true,
      },
      {
        branchId: '0002',
        branchName: '荻窪支店',
        projectId: '0000000003',
        projectName:
          '東京新都心（NEW）新荻窪ベルグプレシャデス田町リニューアル建設プロジェクト（仮名）長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い',
        projectInsertDatetime: '2020-04-23 09:00:00.000000',
        serviceId: '0000140',
        serviceName: 'cacicar for GS',
        enabled: true,
      },
      {
        branchId: '0002',
        branchName: '荻窪支店',
        projectId: '0000000003',
        projectName:
          '東京新都心（NEW）新荻窪ベルグプレシャデス田町リニューアル建設プロジェクト（仮名）長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い名前です。長い',
        projectInsertDatetime: '2020-04-23 09:00:00.000000',
        serviceId: '0000150',
        serviceName: 'CCUSデータ連携サービス',
        enabled: true,
      },
      // 0000000004
      {
        branchId: '0002',
        branchName: '荻窪支店',
        projectId: '0000000004',
        projectName: 'WAリプレイス',
        projectInsertDatetime: '2020-04-25 09:00:00.000000',
        serviceId: '0000110',
        serviceName: 'ワークサイト',
        enabled: true,
      },
      // 0000000005
      {
        branchId: '0002',
        branchName: '荻窪支店',
        projectId: '0000000005',
        projectName: '（仮）AABBコメリ',
        projectInsertDatetime: '2020-04-21 09:00:00.000000',
        serviceId: '0000130',
        serviceName: 'スキルマップサイト',
        enabled: true,
      },
    ],
  },
  {
    companyId: '0000000001',
    companyName: 'テスト用元請　小林コンツェルン',
    updateDatetime: '2020-04-22 09:00:00.000000',
    primeProjects: [
      {
        branchId: '0001',
        branchName: '小林支店',
        projectId: '0000000002',
        projectName: '小林ビル建設プロジェクト',
        projectInsertDatetime: '2020-04-20 09:00:00.000000',
        serviceId: '0000130',
        serviceName: 'スキルマップサイト',
        enabled: false,
      },
    ],
  },
]
// ランダムなデータ
for (let i = 0; i < 5000; i++) {
  database[0].primeProjects.push({
    branchId: '0001',
    branchName: Math.random().toString(32).substring(2) + '支店',
    projectId: Math.random().toString(32).substring(2),
    projectName: Math.random().toString(32).substring(2) + 'プロジェクト',
    projectInsertDatetime: '2020-04-20 09:00:00.000000',
    serviceId: '0000130',
    serviceName: 'スキルマップサイト',
    enabled: false,
  })
}
for (let i = 0; i < 500; i++) {
  database[1].primeProjects.push({
    branchId: '0001',
    branchName: Math.random().toString(32).substring(2) + '支店',
    projectId: Math.random().toString(32).substring(2),
    projectName: Math.random().toString(32).substring(2) + 'プロジェクト',
    projectInsertDatetime: '2020-04-20 09:00:00.000000',
    serviceId: '0000130',
    serviceName: 'スキルマップサイト',
    enabled: false,
  })
}

export const getPrimeProjects = (companyId: string): any => {
  if (companyId === '0000000003') return UNAUTHORIZED_ERROR
  if (companyId === '0000000004') return INTERNAL_SERVER_ERROR

  const primeProjects = database.find((p) => p.companyId === companyId)
  if (!primeProjects) return NO_DATA_ERROR

  primeProjects.primeProjects.sort((a, b) => {
    // 登録日降順
    if (a.projectInsertDatetime < b.projectInsertDatetime) return 1
    if (a.projectInsertDatetime > b.projectInsertDatetime) return -1

    return 0
  })

  return primeProjects
}
