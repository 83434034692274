import IconSkillMap from 'assets/portal_icon_skillMap_prime.png'
import IconWorksite from 'assets/portal_icon_work.png'
import { getServiceByName } from './services'

export interface RowData {
  rowNo: number
  branchName: string
  projectId: string
  projectName: string
  insertDatetime: string
  worksite: boolean
  skillMap: boolean
  ccus: boolean
  cacicar: null
}

export type ColumnName = keyof RowData
export type CheckableColumnName = 'worksite' | 'skillMap'

export interface ColumnData {
  width: number
  columnName: ColumnName
  label: string
  isCheckbox: boolean
  icon?: string
  dataKey: string
}

export const columns: ColumnData[] = [
  {
    width: 60,
    columnName: 'rowNo',
    label: 'No',
    isCheckbox: false,
    dataKey: 'rowNo',
  },
  {
    width: 130,
    columnName: 'projectId',
    label: 'プロジェクトID',
    isCheckbox: false,
    dataKey: 'projectId',
  },
  {
    width: 270,
    columnName: 'projectName',
    label: 'プロジェクト名',
    isCheckbox: false,
    dataKey: 'projectName',
  },
  {
    width: 200,
    columnName: 'branchName',
    label: '支店名',
    isCheckbox: false,
    dataKey: 'branchName',
  },
  {
    width: 120,
    columnName: 'insertDatetime',
    label: '登録日',
    isCheckbox: false,
    dataKey: 'insertDatetime',
  },
  {
    width: 110,
    columnName: 'worksite',
    label: getServiceByName('worksite').label,
    isCheckbox: true,
    icon: IconWorksite,
    dataKey: 'worksite',
  },
  {
    width: 140,
    columnName: 'skillMap',
    label: getServiceByName('skillMap').label,
    isCheckbox: true,
    icon: IconSkillMap,
    dataKey: 'skillMap',
  },
  {
    width: 80,
    columnName: 'ccus',
    label: getServiceByName('ccus').label,
    isCheckbox: true,
    icon: '',
    dataKey: 'ccus',
  },
  {
    width: 130,
    columnName: 'cacicar',
    label: getServiceByName('cacicar').label,
    isCheckbox: false,
    icon: '',
    dataKey: 'cacicar',
  },
]
