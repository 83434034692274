export const UNAUTHORIZED_ERROR = {
  code: 'UNAUTHORIZED',
  message: '実行する権限がありません。',
}

export const INTERNAL_SERVER_ERROR = {
  code: 'INTERNAL_SERVER_ERROR',
  message: '予期せぬエラーが発生しました。',
}

export const NO_DATA_ERROR = {
  code: 'NO_DATA',
  message: '該当するデータが存在しません。',
}
