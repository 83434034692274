import Repository from './repository'
import { AxiosResponse } from 'axios'
import * as querystring from 'querystring'

export interface IPrimeContractCounts {
  /** プライムプロジェクト契約件数 */
  primeLimitCount: number
  /** プライムプロジェクト選択件数 */
  primeSelectCount: number
  /** プロジェクト登録件数 */
  projectRegisteredCount: number
  /** プロジェクト上限数 */
  projectLimitCount: number
}

export default {
  async get(
    companyId: string,
    isGslite: boolean,
  ): Promise<AxiosResponse<IPrimeContractCounts>> {
    const qs = querystring.stringify({ isGslite })

    return Repository.get<IPrimeContractCounts>(
      `/companies/${companyId}/contracts/count?${qs}`,
    )
  },
}
