import {
  IInitializeActionType as ActionType,
  IInitializeAction as Action,
} from './types'

/**
 * stateを初期化する Action Creator
 */
export const initialize = (): Action => ({
  type: ActionType.INITIALIZE,
})
