import React from 'react'
import { Grid } from '@material-ui/core'
import { Copyright } from '../login/Copyright'

export const Footer = (): JSX.Element => {
  return (
    <Grid container justify="center">
      <Copyright />
    </Grid>
  )
}
