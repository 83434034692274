import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { SearchForms } from './SearchForms'
import { PrimeProjectCountItems } from './PrimeProjectCountItems'
import { usePrimeServiceProjectHook } from '../../../../hooks/usePrimeServiceProject'
import { useAuth } from 'hooks/useAuth'

export const Toolbar = (): JSX.Element => {
  const CompanyNameDescription = () => {
    const state = usePrimeServiceProjectHook()

    return React.useMemo(
      () => (
        <Typography
          style={{
            marginTop: '3px',
            marginLeft: '8px',
            fontSize: 16,
          }}
        >
          企業名：{state.companyName}
        </Typography>
      ),
      [state.companyName],
    )
  }

  const { isManager } = useAuth()

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      {isManager && <CompanyNameDescription />}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={5}>
          <PrimeProjectCountItems />
        </Grid>
        <Grid item xs={7}>
          <SearchForms />
        </Grid>
      </Grid>
    </Grid>
  )
}
