/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import * as React from 'react'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import ToolTip from 'view/components/ToolTip'
import { CheckBoxHeaderColumn } from './CheckBoxHeaderColumn'
import { RowData, columns, ColumnData, CheckableColumnName } from '../columns'
import { useDispatch } from 'react-redux'
import { selectFor } from 'reducers/projectPrimeServices/actions'
import { useAuth } from 'hooks/useAuth'

const HeaderColumn = (props: { column: ColumnData }) => {
  const { column } = props

  const { isHelpDesk } = useAuth()

  const isCheckBox = column.isCheckbox && column.dataKey !== 'ccus'

  return (
    <>
      {isCheckBox && !isHelpDesk ? (
        <CheckBoxHeaderColumn columnName={column.columnName} />
      ) : null}

      <Typography variant="caption">{column.label}</Typography>

      {column.icon ? (
        <img src={column.icon} style={{ height: '20px' }} alt="service icon" />
      ) : null}
    </>
  )
}

type CheckBoxCellProps = {
  column: ColumnData
  row: RowData
}

/**
 * 指定された 行(row), column(列) のチェックボックスを表示するコンポーネント
 *
 * カラム名(columnName) と 該当行のプロジェクトID(projectId) をもとに、
 * Redux の state で管理される チェックボックス の値を更新する selectFor アクションを dispatch する。
 */
const CheckBoxCell = (props: CheckBoxCellProps) => {
  const { column, row } = props
  const dispatch = useDispatch()

  const { isHelpDesk } = useAuth()

  return (
    <Checkbox
      disabled={isHelpDesk}
      checked={(row as any)[column.dataKey]}
      color="primary"
      onClick={() =>
        dispatch(
          selectFor({
            columnName: column.dataKey as CheckableColumnName,
            projectId: row.projectId,
          }),
        )
      }
    />
  )
}

const CCUSCheckBoxCell = ({ checked }: { checked: boolean }) => {
  const information = 'グリーンサイトにて設定する必要があります。'

  return (
    <ToolTip title={information}>
      <div>
        <Checkbox checked={checked} disabled />
      </div>
    </ToolTip>
  )
}

const CacicarCell = () => {
  const information = 'cacicar for 建設は全プロジェクトで利用可能です。'

  return React.useMemo(
    () => (
      <ToolTip title={information}>
        <div>
          <Checkbox checked disabled />
        </div>
      </ToolTip>
    ),
    [],
  )
}

/**
 * 文字列（text）を指定された上限（limit）まで表示するコンポーネント
 *
 * 切り取られた箇所は「...」３点リーダーとして表示し、オンマウスでツールチップ表示される。
 * CSS の ellipsis を使用しても良かったが、汎用的に使いづらいため JavaScript のコンポーネントとして用意している。
 */
const WrapToolTipIfNeeded = (props: { text: string; limit: number }) => {
  const { text, limit } = props
  if (text.length > limit) {
    return (
      <ToolTip title={text}>
        <div>{text.slice(0, limit) + '...'}</div>
      </ToolTip>
    )
  }

  return <div>{text}</div>
}

const TextCell = ({ text }: { text: string }) => {
  return (
    <Typography variant="subtitle2" align="left">
      <WrapToolTipIfNeeded text={text} limit={20} />
    </Typography>
  )
}

const Cell = (props: any) => {
  const { column, row, rowIndex } = props
  if (rowIndex === 0) {
    return <HeaderColumn column={column} />
  }
  if (column.dataKey === 'ccus') {
    return <CCUSCheckBoxCell checked={row[column.dataKey]} />
  }
  if (column.dataKey === 'cacicar') {
    return <CacicarCell />
  }
  if (column.isCheckbox) {
    return <CheckBoxCell column={column} row={row} />
  }

  return <TextCell text={row[column.dataKey]} />
}

type Props = {
  rows: RowData[]
  height: number
}

type CellRendererParams = {
  columnIndex: number
  key: string
  rowIndex: number
  style: React.CSSProperties
}

/**
 * 水平方向にスクロール可能なテーブルコンポーネント
 *
 * プロジェクトごとのプライムサービス選択状況を描画する
 */
export default class HorizontalScrollTable extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)
    this.cellRenderer = this.cellRenderer.bind(this)
  }

  private cellRenderer(params: CellRendererParams): JSX.Element {
    const { columnIndex, rowIndex, key, style } = params

    const column = columns[columnIndex]

    const justifyContent = (column: ColumnData): 'left' | 'center' => {
      const leftColumns = ['projectName', 'branchName']
      if (leftColumns.indexOf(column.dataKey) !== -1) return 'left'
      if (column.isCheckbox && column.dataKey !== 'ccus') return 'left'

      return 'center'
    }

    const displayStyle = {
      justifyContent: justifyContent(column),
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #eee',
    }

    const row: any = this.props.rows[rowIndex - 1] // rowIndex が 0 の場合はヘッダーを表示しているため、項番は１つ落としたものを取得している。

    return (
      <div
        key={key}
        style={{ ...style, ...displayStyle }}
        data-testid={`cell-${key}`}
      >
        <Cell column={column} row={row} rowIndex={rowIndex} />
      </div>
    )
  }

  render(): JSX.Element {
    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <MultiGrid
            cellRenderer={this.cellRenderer}
            columnWidth={({ index }) => columns[index].width}
            columnCount={columns.length}
            enableFixedColumnScroll
            enableFixedRowScroll
            height={this.props.height}
            rowHeight={this.props.height / 13} // ヘッダーを含めて画面に 13行表示する
            rowCount={this.props.rows.length + 1} // ヘッダーを含めると +1
            fixedRowCount={1}
            fixedColumnCount={5}
            width={width}
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
    )
  }
}
