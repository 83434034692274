import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch } from 'react-redux'
import { initialize } from 'reducers/projectPrimeServices/actions'
import { contractInitialize } from 'reducers/contractsCountServices/actions'

export const LinkArrowButton = ({ to }: { to: string }): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <IconButton
      color="primary"
      onClick={() => {
        // 次の画面のstateをクリアする
        dispatch(initialize())
        dispatch(contractInitialize())

        history.push(to)
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  )
}
