/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingGrid: {
      padding: theme.spacing(1),
    },
  }),
)
export const PaddingGrid = (props: any) => {
  const classes = useStyles()

  return <Grid className={classes.paddingGrid} {...props} />
}
