import React from 'react'
import { fetchPrimeServiceProjects } from 'reducers/projectPrimeServices/actions'
import { TablePagination } from '@material-ui/core'
import { usePrimeServiceProjectHook } from '../../../../hooks/usePrimeServiceProject'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchPrimeContractCounts } from 'reducers/contractsCountServices/actions'

const Pagination = (): JSX.Element => {
  const dispatch = useDispatch()

  const state = usePrimeServiceProjectHook()
  const totalCount = state.totalCount
  const page = state.page
  const perPage = state.perPage
  const condition = state.searchCondition
  const isGsLite = state.isGsLite
  const pageTokens = state.pageTokens
  const { companyId } = useParams<{ companyId: string }>()

  return (
    <TablePagination
      rowsPerPageOptions={[100, 200, 500]}
      labelRowsPerPage="表示する行数："
      component="div"
      count={totalCount}
      rowsPerPage={perPage}
      page={page}
      onChangePage={(_event, newPage) => {
        dispatch(
          fetchPrimeServiceProjects({
            companyId,
            page: newPage,
            perPage,
            isGsLite,
            pageTokens,
            condition,
          }),
        )
        dispatch(fetchPrimeContractCounts(companyId, isGsLite))
      }}
      onChangeRowsPerPage={(event) => {
        dispatch(
          fetchPrimeServiceProjects({
            companyId,
            page: 0,
            perPage: parseInt(event.target.value, 10),
            isGsLite,
            pageTokens: [''],
            condition,
          }),
        )
        dispatch(fetchPrimeContractCounts(companyId, isGsLite))
      }}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to}/${count}`}
      style={
        state.api.isUpdating || state.api.isFetching
          ? { visibility: 'hidden' }
          : { height: '50%' }
      }
    />
  )
}

export default Pagination
