/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Button from '@material-ui/core/Button'

export const ConfirmButton = (props: any): JSX.Element => {
  const { handleClick, ...rest } = props

  return (
    <Button onClick={handleClick} variant="contained" color="primary" {...rest}>
      登録
    </Button>
  )
}
