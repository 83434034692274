/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Button from '@material-ui/core/Button'
import { saveAs } from 'file-saver'
import primeProjectServicesRepository from 'apis/repositories/primeProjectServices'
import { useSnackbar } from 'notistack'
import getLogger from '../../../logger'
import { useParams, useHistory } from 'react-router'
import { UnauthorizedErrorStatus } from '../../../constants'

export const CsvDownloadButton = (props: any): JSX.Element => {
  const { isGslite, ...rest } = props
  const { companyId } = useParams<{ companyId: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const logger = getLogger('@CsvDownloadButton')
  const history = useHistory()
  const download = async () => {
    return await primeProjectServicesRepository
      .download(companyId, isGslite)
      .then(({ fileName, file }) => {
        saveAs(file, fileName)
      })
      .catch((error) => {
        logger.error('CSVダウンロードが失敗しました。', error)
        if (error.response.status === UnauthorizedErrorStatus) {
          history.push('/auth')
        } else {
          const message = 'CSVダウンロードが失敗しました。'
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  }

  return (
    <Button onClick={download} variant="contained" color="primary" {...rest}>
      プロジェクト一覧CSV出力
    </Button>
  )
}
