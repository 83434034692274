import { ManagerType } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  loginFailedAction,
  loginSucceedAction,
  logoutAction,
  requestLoginAction,
} from 'reducers/login/me'
import { AppState } from 'reducers/store'
import authApi, { IUser } from 'apis/repositories/auth'
import { useHistory } from 'react-router'
import getLogger from 'logger'
import { useCallback } from 'react'
import { AxiosError } from 'axios'

const logger = getLogger('@callBack')
type Auth = {
  authorize: () => void
  callback: () => void
  logout: () => void
  user: IUser | null
  error: AxiosError | null
  isManager: boolean
  isHelpDesk: boolean
}

const managers = [ManagerType.HelpDesk, ManagerType.McdpOperator]
const companyChiefs = [ManagerType.CompanyChief, ManagerType.CompanySubChief]

/**
 * 認証・認可処理を扱うカスタムHooks
 */
export const useAuth = (): Auth => {
  const dispatch = useDispatch()
  const history = useHistory()

  /**
   * SSOログイン
   *
   * /auth エンドポイントに対して API リクエストを行い、ユーザ認証のクライアントと認証用 URL を取得します。
   * ユーザ認証のクライアント情報は Set-Cookie ヘッダに codeVerifier というキー値で含まれています。
   * 認証用 URL は authorizationUrl というキー値でリクエストボディに含まれています。
   *
   * 正常に API レスポンスを取得した後、認証用 URL(SSOエンドポイント) にリクエストします。
   */
  const authorize = useCallback(() => {
    authApi.auth().then(({ data }) => {
      if (process.env.REACT_APP_USE_MOCK === 'true') {
        window.location.assign('/auth/callback')
      } else {
        console.log(data.authorizationUrl)
        window.location.assign(data.authorizationUrl)
      }
    })
  }, [])

  /**
   * 認証コールバック
   *
   * 認証用 URL(SSOエンドポイント) から取得したトークン付きで /auth/callback APIを実行し、
   * ユーザ情報を取得する。
   */
  const callback = useCallback(() => {
    dispatch(requestLoginAction())
    authApi
      .callBack(window.location.search)
      .then(({ data: user }) => {
        dispatch(loginSucceedAction(user))

        if (companyChiefs.includes(user.managerType)) {
          history.replace({ pathname: `/companies/${user.companyId}/services` })
        } else {
          history.replace({ pathname: '/companies' })
        }
      })
      .catch((error: AxiosError) => {
        // 企業担当者、ヘルプデスク、MCDP運用担当者以外はサーバサイドから403エラーが返却される
        dispatch(loginFailedAction(error))
        const status = error?.response?.status
        if (status === 401) {
          logger.error('ユーザ情報がありません。', error)
          history.push('/404')
        } else if (status === 403) {
          logger.error('参照する権限がありません。', error)
          history.push('/404')
        } else {
          logger.error('認証コールバックAPIのシステムエラーです。', error)
          history.push('/500')
        }
      })
  }, [dispatch, history])

  /**
   * ログアウト
   *
   * cookie に保持しているセッション情報を破棄する
   * stateクリアを実行するとレンダリングによって権限チェックが実行されてしまう。
   * そのため、Cookieの削除を行った後、/logout 画面に遷移しstateをクリアする。
   */
  const logout = useCallback(() => {
    authApi.logout().then(() => {
      history.push('/logout')
      dispatch(logoutAction())
    })
  }, [history, dispatch])

  const state = useSelector((state: AppState) => state.me)
  const isManager = managers.includes(state.user?.managerType || '')
  const isHelpDesk = state?.user?.managerType === ManagerType.HelpDesk

  return {
    authorize,
    callback,
    logout,
    user: state.user,
    error: state.error,
    isManager,
    isHelpDesk,
  }
}
