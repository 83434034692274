/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, compose } from 'redux'
import { combineReducers } from 'redux'
import me, { IAuthState } from './login/me'
import thunk from 'redux-thunk'
import primeServiceProject from './projectPrimeServices/reducer'
import primeContractCounts from './contractsCountServices/reducer'
import companies, { ICompaniesState } from './companySelectsServices/reducer'
import { IPrimeServiceProjectState } from './projectPrimeServices/types'
import { IPrimeContractCountsState } from './contractsCountServices/types'
import { createLogger } from 'redux-logger'
export interface AppState {
  me: IAuthState
  primeServiceProject: IPrimeServiceProjectState
  primeContractCounts: IPrimeContractCountsState
  companies: ICompaniesState
}

const reducer = combineReducers({
  me,
  primeServiceProject,
  primeContractCounts,
  companies,
})

/**
 * Logs all actions and states after they are dispatched.
 */
const middleware: any[] = [thunk]
if (process.env.REACT_APP_LOG_LEVEL === 'info') {
  const logger = createLogger({
    level: 'info',
    collapsed: true,
    diff: true,
  })
  middleware.push(logger)
}

// TypeScript で Redux Dev Tools を使用する
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleware)),
)

export default store
