/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { thunkedFetchCompaniesAction } from 'reducers/companySelectsServices/actions'
import { PaddingGrid } from '../../components/logout/PaddingStyledGrid'
import { SearchButton } from 'view/components/form/SearchButton'
import { TextField, Typography } from '@material-ui/core'
import { useCompaniesHook } from 'hooks/useCompanies'

export interface ISearchParams {
  companyId?: string
  companyName?: string
}

export const SearchForms = (): JSX.Element => {
  const dispatch = useDispatch()
  const search = (data: any) => {
    dispatch(thunkedFetchCompaniesAction({ ...data }))
  }
  const state = useCompaniesHook()

  const { register, handleSubmit, errors } = useForm<ISearchParams>({
    mode: 'onChange',
  })

  return (
    <form noValidate autoComplete="off">
      <PaddingGrid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid container>
          <PaddingGrid item xs={5}>
            <TextField
              label="企業ID"
              variant="outlined"
              size="small"
              fullWidth={true}
              type="search"
              inputRef={register({
                pattern: {
                  value: /^[0-9a-zA-Z]+$/,
                  message: '半角英数字で入力してください',
                },
              })}
              name="companyId"
              defaultValue={state.searchCondition.companyId}
            />
            <Typography variant="caption" color="error">
              {errors.companyId && errors.companyId.message}
            </Typography>
          </PaddingGrid>
          <PaddingGrid item xs={5}>
            <TextField
              label="企業名"
              variant="outlined"
              size="small"
              fullWidth={true}
              type="search"
              inputRef={register({
                pattern: {
                  value: /[^!"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]+/,
                  message: '半角記号を入力することはできません。',
                },
              })}
              name="companyName"
              defaultValue={state.searchCondition.companyName}
            />
            <Typography variant="caption" color="error">
              {errors.companyName && errors.companyName.message}
            </Typography>
          </PaddingGrid>
          <PaddingGrid item xs={2}>
            <SearchButton onClick={handleSubmit(search)} />
          </PaddingGrid>
        </Grid>
      </PaddingGrid>
    </form>
  )
}
