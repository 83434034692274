/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Controller, Control } from 'react-hook-form'

export const FormControlCheckBox = (props: {
  value: string
  label: string
  control: Control<Record<string, any>>
  checked: boolean
}): JSX.Element => {
  const { value, label, control, checked } = props

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Controller
        as={<Checkbox color="primary" />}
        name={value}
        defaultValue={checked}
        control={control}
        data-testid={`form-control-checkbox-${label}`}
      />
      <Typography>{label}</Typography>
    </Grid>
  )
}
