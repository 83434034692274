/* eslint-disable @typescript-eslint/no-explicit-any */
import { RowData, CheckableColumnName } from 'view/pages/primeProjects/columns'
import {
  IPrimeServiceProjectAction,
  IPrimeServiceProjectActionType as ActionType,
  IPrimeServiceProjectState,
} from './types'
import { RowController } from './RowController'

export const initialState = {
  companyName: '',
  totalCount: 0,
  rows: [],
  initialSelectedProjectIds: [],
  selectedProjectIds: [],
  checkedProjectIds: [],
  updateDatetime: '',
  page: 0,
  perPage: 100,
  api: {
    isFetching: false,
    isUpdating: false,
  },
  isGsLite: false,
  searchCondition: {
    projectId: '',
    projectName: '',
  },
  liteSearchCondition: {},
  pageTokens: [''],
  searchConditionExpanded: false,
}
/**
 * プロジェクトごとのプライムサービス選択数を保持する state を更新する reducer
 * dispatch時にコールバックされる
 * @param state
 * @param action
 */
const reducer = (
  state: IPrimeServiceProjectState = initialState,
  action: IPrimeServiceProjectAction,
): IPrimeServiceProjectState => {
  switch (action.type) {
    case ActionType.INITIALIZE:
      return initialState
    case ActionType.INITIALIZE_API:
      return {
        ...state,
        api: initialState.api,
      }
    case ActionType.FETCH_REQUEST:
      return {
        ...state,
        companyName: '',
        api: {
          ...initialState.api,
          method: 'GET',
          isFetching: true,
        },
      }
    case ActionType.FETCH_SUCCEED:
      return {
        ...state,
        api: {
          ...initialState.api,
          method: 'GET',
          status: 200,
        },
        initialSelectedProjectIds: action.selectedProjectIds!,
        selectedProjectIds: action.selectedProjectIds!,
        companyName: action.companyName!,
        updateDatetime: action.updateDatetime!,
        totalCount: action.totalCount!,
        rows: action.rows!,
        page: action.page!,
        perPage: action.perPage!,
        pageTokens: action.pageTokens!,
        // 検索後はチェックした内容を破棄
        checkedProjectIds: [],
        isGsLite: action.isGsLite!,
      }
    case ActionType.FETCH_FAILED:
      return {
        ...state,
        api: {
          ...initialState.api,
          method: 'GET',
          status: action.error.response.status,
          error: action.error,
        },
      }
    case ActionType.SELECT_CLICK_FOR:
      return {
        ...state,
        api: initialState.api,
        rows: clickFor(action.columnName!, state.rows, action.projectId!),
        selectedProjectIds: updateSelectedProjectIds(
          state.selectedProjectIds,
          state.rows,
          action.projectId!,
        ),
        checkedProjectIds: updateCheckedProjectIds(
          state.checkedProjectIds,
          action.projectId!,
        ),
      }
    case ActionType.SELECT_ALL_CLICK_FOR:
      return {
        ...state,
        api: initialState.api,
        rows: clickAllFor(
          action.columnName!,
          state.rows,
          action.first!,
          action.last!,
          action.checked!,
        ),
        selectedProjectIds: updateAllSelectedProjectIds(
          state.selectedProjectIds,
          state.rows,
          action.first!,
          action.last!,
          action.checked!,
        ),
        checkedProjectIds: updateAllCheckedProjectIds(
          state.checkedProjectIds,
          state.rows,
          action.first!,
          action.last!,
        ),
      }
    case ActionType.UPDATE_PAGE:
      return {
        ...state,
        api: initialState.api,
        page: action.page!,
      }
    case ActionType.UPDATE_ROWS_PER_PAGE:
      return {
        ...state,
        api: initialState.api,
        page: initialState.page,
        perPage: action.perPage!,
      }
    case ActionType.EXPAND_SEARCH_PANEL:
      return {
        ...state,
        api: initialState.api,
        searchConditionExpanded: !state.searchConditionExpanded,
      }
    case ActionType.SEARCH_CLICK_FOR:
      return {
        ...state,
        api: initialState.api,
        searchCondition: action.searchCondition!,
        // 検索を行った場合は更新対象を初期化する
        checkedProjectIds: initialState.checkedProjectIds,
        // 検索を行った場合、検索条件ウィンドウは閉じる
        searchConditionExpanded: false,
      }
    case ActionType.LITE_SEARCH_CLICK_FOR:
      return {
        ...state,
        api: initialState.api,
        liteSearchCondition: action.liteSearchCondition!,
        // 検索を行った場合は更新対象を初期化する
        checkedProjectIds: initialState.checkedProjectIds,
        // 検索を行った場合、検索条件ウィンドウは閉じる
        searchConditionExpanded: false,
      }
    case ActionType.CHANGE_PROJECT_TAB:
      return {
        ...state,
        api: initialState.api,
        isGsLite: !state.isGsLite,
        page: 0,
        perPage: 100,
      }
    case ActionType.UPDATE_REQUEST:
      return {
        ...state,
        api: {
          ...initialState.api,
          method: 'POST',
          isUpdating: true,
        },
      }
    case ActionType.UPDATE_SUCCEED:
      return {
        ...state,
        api: {
          ...initialState.api,
          method: 'POST',
          status: 200,
        },
        updateDatetime: action.updateDatetime!,
        // 更新成功の場合は更新対象を初期化する
        checkedProjectIds: [],
      }
    case ActionType.UPDATE_FAILED:
      return {
        ...state,
        api: {
          ...initialState.api,
          error: action.error,
          status: action.error.response.status,
          method: 'POST',
        },
      }
    default:
      // 初期化時はここに来る（initialStateのオブジェクトが返却される）
      return state
  }
}

export default reducer

const updateAllSelectedProjectIds = (
  prevSelectedProjects: string[],
  prevRows: RowData[],
  first: number,
  last: number,
  checked: boolean,
): string[] => {
  const isCurrentPage = (i: number) => first <= i && i < last
  const operatedProjectIds: any = []
  prevRows.forEach((row, index) => {
    if (isCurrentPage(index)) {
      operatedProjectIds.push(row.projectId)
    }
  })

  // 差分だけ削除または追加する
  if (checked) {
    // 追加または変化なし
    const result = [...prevSelectedProjects, ...operatedProjectIds]
    const removedDuplicates = result.filter((x, i, self) => {
      return self.indexOf(x) === i
    })

    return removedDuplicates
  }

  // 削除
  const rowController = new RowController(prevRows)
  const deleteProjectIds: string[] = []
  for (const projectId of operatedProjectIds) {
    // 指定したプロジェクトが１つもプライムサービスを選択していなければ削除対象
    if (!rowController.evenOneCheckForRow(projectId)) {
      deleteProjectIds.push(projectId)
    }
  }

  return prevSelectedProjects.filter((projectId) => {
    for (const deleteProjectId of deleteProjectIds) {
      if (projectId === deleteProjectId) {
        // projectId がどれか１つでもチェックを入れているか

        return false
      }
    }

    return true
  })
}

const updateAllCheckedProjectIds = (
  prevCheckedProjects: string[],
  prevRows: RowData[],
  first: number,
  last: number,
): string[] => {
  const isCurrentPage = (i: number) => first <= i && i < last
  const operatedProjectIds: any = []
  prevRows.forEach((row, index) => {
    if (isCurrentPage(index)) {
      operatedProjectIds.push(row.projectId)
    }
  })
  const result = [...prevCheckedProjects, ...operatedProjectIds]
  const removedDuplicates = result.filter((x, i, self) => {
    return self.indexOf(x) === i
  })

  return removedDuplicates
}

const updateSelectedProjectIds = (
  prevSelectedProjects: string[],
  prevRows: RowData[],
  projectId: string,
) => {
  const rowController = new RowController(prevRows)

  if (rowController.evenOneCheckForRow(projectId)) {
    // 追加または変化なし
    const selectedProjects = [...prevSelectedProjects, projectId]
    const removedDuplicates = selectedProjects.filter((x, i, self) => {
      return self.indexOf(x) === i
    })

    return removedDuplicates
  }

  // 削除
  return prevSelectedProjects.filter((item) => {
    return item !== projectId
  })
}

const updateCheckedProjectIds = (
  prevCheckedProjects: string[],
  projectId: string,
) => {
  // 一度もチェックボックス操作がない場合一覧に追加
  if (!prevCheckedProjects.includes(projectId)) {
    prevCheckedProjects.push(projectId)
  }

  return prevCheckedProjects
}

/**
 * クリックされたチェックボックスの行（id）・ 列（columnName）を指定し、
 * チェックボックスの値を反転する（ONの場合はOFFに、OFFの場合はONに更新する）
 * 更新された state を返却する
 * @param columnName チェックボックスの列 カラム名
 * @param id 更新対象のチェックボックスインデックス
 * @param prevRows 更新前の store に保管されている rows
 */
const clickFor = (
  columnName: CheckableColumnName,
  prevRows: RowData[],
  projectId: string,
) => {
  return prevRows.map((row) => {
    if (row.projectId === projectId) {
      row[columnName] = !row[columnName]
    }

    return row
  })
}

/**
 * クリックされたチェックボックスの 列（columnName）を指定し、
 * １ページに表示されている該当列のチェックボックスの値を更新する。
 * 更新された state を返却する
 * @param columnName チェックボックスの列 カラム名
 * @param prevRows 更新前の store に保管されている rows
 * @param first 更新対象のチェックボックスインデックス（始め）更新対象に含む
 * @param last 更新対象のチェックボックスインデックス（終わり）更新対象に含まない
 * @param checked 更新後の値
 */
const clickAllFor = (
  columnName: CheckableColumnName,
  prevRows: RowData[],
  first: number,
  last: number,
  checked: boolean,
) => {
  const isCurrentPage = (i: number) => first <= i && i < last

  return prevRows.map((row: RowData, index: number) => {
    if (isCurrentPage(index)) {
      row[columnName] = checked
    }

    return row
  })
}
