import React from 'react'
import Grid from '@material-ui/core/Grid'
export const Footer = (): JSX.Element => {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="center"
      alignItems="center"
    ></Grid>
  )
}
