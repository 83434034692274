import React from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

export const HistoryBackButton = (): JSX.Element => {
  const history = useHistory()

  return (
    <Button onClick={() => history.goBack()} variant="outlined" color="primary">
      戻る
    </Button>
  )
}
