import { LogLevelString } from 'bunyan'
import {
  createLogger,
  stdSerializers,
  ConsoleFormattedStream,
} from 'browser-bunyan'

const level = process.env.REACT_APP_LOG_LEVEL as LogLevelString

/**
 * ログ出力処理
 */
export const getLogger = (name: string) => {
  const logger = createLogger({
    name,
    level,
    stream: new ConsoleFormattedStream({ logByLevel: true }),
    serializers: { err: stdSerializers.err },
    src: process.env.REACT_APP_LOG_LEVEL === 'info',
  })

  return logger
}

export default getLogger
