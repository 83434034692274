export default [
  {
    branchName: '荻窪支店',
  },
  {
    branchName: '恵比寿支店',
  },
  {
    branchName: '東陽町支店',
  },
]
