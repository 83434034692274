/* eslint-disable @typescript-eslint/no-explicit-any */
import PrimeServiceProjectAPIClient, {
  IPrimeProject,
  IPrimeServiceProjectsPostRequestBody,
  IPrimeServiceProjectsQueryParams,
} from 'apis/repositories/primeProjectServices'
import { RowData, CheckableColumnName } from 'view/pages/primeProjects/columns'
import {
  IPrimeServiceProjectActionType as ActionType,
  IPrimeServiceProjectAction as Action,
  ISearchCondition,
} from './types'
import { primeServices } from 'view/pages/primeProjects/services'
import getLogger from '../../logger'

const logger = getLogger('@projectPrimeServices')

export const initialize = (): Action => ({ type: ActionType.INITIALIZE })

export const initializeApi = (): Action => ({ type: ActionType.INITIALIZE_API })

/**
 * APIリクエストを開始する Action Creator
 */
export const request = (): Action => ({ type: ActionType.FETCH_REQUEST })

type SucceedParam = {
  totalCount: number
  rows: RowData[]
  selectedProjectIds: string[]
  companyName: string
  updateDatetime: string
  page: number
  perPage: number
  pageTokens: string[]
  isGsLite: boolean
}
/**
 * APIリクエストの成功時、取得したデータを store に保持するための Action Creator
 * @param rows
 */
export const succeed = (param: SucceedParam): Action => ({
  type: ActionType.FETCH_SUCCEED,
  ...param,
})

/**
 * APIリクエストの失敗時、返却されたエラーを store に保持するための Action Creator
 * @param error
 */
export const failed = (error: any): Action => ({
  type: ActionType.FETCH_FAILED,
  error,
})

export type SelectAllForParam = {
  columnName: CheckableColumnName
  first: number // 更新対象のチェックボックスインデックス（始め）更新対象に含む
  last: number // 更新対象のチェックボックスインデックス（終わり）更新対象に含まない
  checked: boolean
}
/**
 * 指定された範囲のチェックボックス の ON/OFF を切り替える Action Creator
 * @param param
 */
export const selectAllFor = (param: SelectAllForParam): Action => ({
  type: ActionType.SELECT_ALL_CLICK_FOR,
  ...param,
})

export type SelectForParam = {
  columnName: CheckableColumnName
  projectId: string // 更新対象のチェックボックスインデックス
}

export type searchParam = {
  companyId: string
  page: number
  perPage: number
  isGsLite: boolean
  pageTokens: string[]
  condition?: ISearchCondition
}
/**
 * 指定されたチェックボックス の ON/OFF を切り替える Action Creator
 * @param param
 */
export const selectFor = (param: SelectForParam): Action => ({
  type: ActionType.SELECT_CLICK_FOR,
  ...param,
})

export const setPage = (page: number): Action => ({
  type: ActionType.UPDATE_PAGE,
  page,
})

export const setPerPage = (perPage: number): Action => ({
  type: ActionType.UPDATE_ROWS_PER_PAGE,
  perPage,
})

/**
 * 検索条件ウィンドウを開閉するアクション
 */
export const expandSearchPanel = (): Action => ({
  type: ActionType.EXPAND_SEARCH_PANEL,
})

/**
 * GS-Liteプロジェクトのタブを切り替えるアクション
 */
export const changeProjectTab = (): Action => ({
  type: ActionType.CHANGE_PROJECT_TAB,
})

/**
 * 検索条件を保持する Action Creator
 * @param param
 */
export const setSearchCondition = (condition: ISearchCondition): Action => ({
  type: ActionType.SEARCH_CLICK_FOR,
  searchCondition: condition,
})

/**
 * Liteプロジェクトの検索条件を保持する Action Creator
 * @param param
 */
export const setLiteSearchCondition = (
  condition: ISearchCondition,
): Action => ({
  type: ActionType.LITE_SEARCH_CLICK_FOR,
  liteSearchCondition: condition,
})

/**
 * 選択中のプロジェクト（行）を取得する。
 * @return プロジェクトIDの配列
 */
export const selectedPrimeProjects = (rows: RowData[]) => {
  const projectIds = []
  for (const row of rows) {
    // どれか一つでもプライムサービスを選択していたら
    if (row.worksite || row.skillMap || row.ccus) {
      projectIds.push(row.projectId)
    }
  }

  return projectIds
}

/**
 * プロジェクトごとのプライムサービス選択状況を取得する Async Action Creator
 *
 */
export const fetchPrimeServiceProjects = (param: searchParam) => {
  const { companyId, page, perPage, isGsLite, pageTokens, condition } = param

  return (dispatch: (arg: Action) => void): Promise<any> => {
    let params: IPrimeServiceProjectsQueryParams = {}

    console.debug('condition : ' + condition)

    if (condition) {
      isGsLite
        ? dispatch(setLiteSearchCondition(condition))
        : dispatch(setSearchCondition(condition))
      params = {
        branchName: condition.branchName || '',
        projectId: condition.projectId || '',
        projectName: condition.projectName || '',
        from: condition.insertDateChecked ? condition.insertDateFrom : '',
        to: condition.insertDateChecked ? condition.insertDateTo : '',
        serviceId: condition.services || [],
        page,
        perPage,
        pageTokens,
        isGslite: isGsLite,
      }
    }

    dispatch(setPage(page))
    dispatch(setPerPage(perPage))
    dispatch(request())

    return PrimeServiceProjectAPIClient.get(companyId, params)
      .then(({ data }) => {
        const rows = formatToRows(
          data.primeProjects,
          params.page!,
          params.perPage!,
        )
        const action = succeed({
          totalCount: data.totalCount,
          rows,
          selectedProjectIds: selectedPrimeProjects(rows),
          companyName: data.companyName,
          updateDatetime: data.updateDatetime,
          page,
          perPage,
          pageTokens: data.pageTokens,
          isGsLite,
        })
        dispatch(action)
      })
      .catch((error) => {
        // TODO: AxiosError に合わせたエラーハンドリング
        // APIリクエストを送信するいくつかの failedAction Creator で共通化したい
        logger.error(
          'プライムプロジェクト取得APIリクエストが失敗しました。',
          error,
        )
        dispatch(failed(error))
      })
  }
}

export const updateRequest = (): Action => ({
  type: ActionType.UPDATE_REQUEST,
})

export type UpdateSucceedResponse = {
  updateDatetime: string
  primeProjects: UpdateSucceedResponsePrimeProjects[]
}
export type UpdateSucceedResponsePrimeProjects = {
  companyId: string
  projectId: string
  serviceId: string
  enabled: boolean
}
// TODO: 更新した結果を store に保存する必要はあるか？
// 更新日時の更新が必要になる
export const updateSucceed = (updateDatetime: string): Action => ({
  type: ActionType.UPDATE_SUCCEED,
  updateDatetime,
})

export const updateFailed = (error: any): Action => ({
  type: ActionType.UPDATE_FAILED,
  error,
})

export const updatePrimeServiceProjects = (
  companyId: string,
  data: IPrimeServiceProjectsPostRequestBody,
) => {
  logger.info('更新内容', data?.primeProjects)

  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(updateRequest())

    // TODO バックエンドで上限数チェクのエラーになった場合のエラーメッセージを実装する
    return await PrimeServiceProjectAPIClient.post(companyId, data)
      .then(({ data }) => {
        return dispatch(updateSucceed(data.updateDatetime))
      })
      .catch((error) => {
        logger.error(
          'プライムプロジェクト登録APIリクエストが失敗しました。',
          error,
        )

        return dispatch(updateFailed(error))
      })
  }
}

/**
 * プライムプロジェクトリスト取得APIから取得した値を行データに整形する
 * @param primeProjects
 */
export const formatToRows = (
  primeProjects: IPrimeProject[],
  page: number,
  perPage: number,
): RowData[] => {
  const projectsMap: { [key: string]: RowData } = {}
  const startNum = Number(page * perPage)
  primeProjects.forEach((i) => {
    const id = i.projectId
    if (typeof projectsMap[id] == 'undefined') {
      // initialization
      projectsMap[id] = {
        rowNo: startNum || 0,
        branchName: i.branchName,
        projectId: i.projectId,
        projectName: i.projectName,
        insertDatetime: formatDateYYYYMMDD(i.projectInsertDatetime),
        worksite: false,
        skillMap: false,
        ccus: false,
        cacicar: null,
      }
    }

    primeServices.forEach((service) => {
      if (i.serviceId === service.serviceId)
        (projectsMap[id] as any)[service.serviceName] = i.enabled
    })
  })

  return flatten(projectsMap)
}

/**
 * 2020-04-20 09:00:00.000000 形式のデータを
 * 2020/04/20 の日付形式に変換する
 *
 * TODO: 正規表現書いてちゃんとやった方がいいかも？
 */
const formatDateYYYYMMDD = (str: string) => {
  const arr = str.split(' ')[0].split('/')

  return `${arr[0]}/${arr[1]}/${arr[2]}`
}

const flatten = (projectsMap: { [key: string]: RowData }): RowData[] => {
  const rows: RowData[] = []
  Object.keys(projectsMap).forEach((projectId, index) => {
    rows.push({
      ...projectsMap[projectId],
      rowNo: Number(projectsMap[projectId].rowNo) + 1 + index,
    })
  })

  return rows
}
