/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid } from '@material-ui/core'
import { FilterSearchForms } from './SearchConditionSelectButton'
import { useForm, Control } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { fetchPrimeServiceProjects } from 'reducers/projectPrimeServices/actions'
import { primeServices } from 'view/pages/primeProjects/services'
import { ControlledAutoCompleteStyledTextField } from '../../../components/form/AutoCompleteStyledTextField'
import { PaddingGrid } from '../../../components/logout/PaddingStyledGrid'
import { useParams } from 'react-router-dom'
import BranchesAPIClient from 'apis/repositories/branches'
import { SearchButton } from 'view/components/form/SearchButton'
import { SearchTextField } from 'view/components/form/TextField'
import format from 'date-fns/format'
import { fetchPrimeContractCounts } from 'reducers/contractsCountServices/actions'
import { usePrimeServiceProjectHook } from '../../../../hooks/usePrimeServiceProject'
import { ISearchCondition } from 'reducers/projectPrimeServices/types'
import { projectSearchConditions } from '../PrimeProjectsPage'

interface ISearchForms {
  projectId: string
  projectName: string
  branchName: string
  insertDateChecked: boolean
  insertDateFrom: Date
  insertDateTo: Date
  skillMap: string
  worksite: string
  ccus: string
}

export const SearchForms = (): JSX.Element => {
  const dispatch = useDispatch()
  const { companyId } = useParams<{ companyId: string }>()
  const state = usePrimeServiceProjectHook()
  const searchCondition: ISearchCondition = projectSearchConditions(state)
  const isGsLite = state.isGsLite

  const search = (data: ISearchForms) => {
    const params = {
      branchName: data.branchName,
      projectId: data.projectId,
      projectName: data.projectName,
      insertDateChecked: data.insertDateChecked,
      insertDateFrom: format(data.insertDateFrom, 'yyyyMMdd'),
      insertDateTo: format(data.insertDateTo, 'yyyyMMdd'),
      services: primeServices
        .filter((s) => (data as any)[s.serviceName])
        .map((s) => s.serviceId),
    }

    // プロジェクト一覧を絞り込み検索する。(ページは初期表示に戻す)
    dispatch(
      fetchPrimeServiceProjects({
        companyId,
        page: 0,
        perPage: state.perPage,
        isGsLite,
        pageTokens: [''],
        condition: params,
      }),
    )
    // プライムプロジェクト選択数などの情報も合わせて更新する。
    dispatch(fetchPrimeContractCounts(companyId, state.isGsLite))
  }

  const { handleSubmit, control } = useForm<ISearchForms>({
    mode: 'onChange',
  })

  return (
    <form noValidate autoComplete="off">
      <PaddingGrid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ paddingTop: '0px' }}
      >
        <Grid container style={{ marginTop: '0px' }}>
          <PaddingGrid item xs={4}>
            <SearchTextField
              control={control}
              label="プロジェクトID"
              name="projectId"
              defaultValue={searchCondition.projectId}
            />
          </PaddingGrid>
          <PaddingGrid item xs={4}>
            <SearchTextField
              control={control}
              label="プロジェクト名"
              name="projectName"
              defaultValue={searchCondition.projectName}
            />
          </PaddingGrid>
          <PaddingGrid item xs={4}>
            <BranchNameTextField control={control} />
          </PaddingGrid>
        </Grid>
        <Grid container>
          <PaddingGrid item xs={10}>
            <FilterSearchForms control={control} />
          </PaddingGrid>
          <PaddingGrid item xs={2}>
            <SearchButton
              onClick={handleSubmit(search)}
              disabled={state.api.isUpdating || state.api.isFetching}
            />
          </PaddingGrid>
        </Grid>
      </PaddingGrid>
    </form>
  )
}

type TextFieldProps = {
  control: Control<Record<string, any>>
}
export const BranchNameTextField = (props: TextFieldProps): JSX.Element => {
  const { control } = props
  const { companyId } = useParams<{ companyId: string }>()
  const [branches, setBranches] = React.useState([''])
  React.useEffect(() => {
    BranchesAPIClient.get(companyId).then(({ data }) => {
      const b: string[] = []
      for (const project of data) {
        b.push(project.branchName)
      }
      setBranches(b)
    })
  }, [companyId])

  return (
    <ControlledAutoCompleteStyledTextField
      label={'支店名'}
      options={sortBy(branches)}
      name={'branchName'}
      control={control}
    />
  )
}

// TODO: 型をちゃんとつける
/**
 * オブジェクト配列を指定されたキーの値で昇順にソートする
 */
const sortBy = (branches: string[]) => {
  return branches
    .filter((x: any, i: any, self: any) => {
      return self.indexOf(x) === i
    })
    .sort((a: any, b: any) => {
      // 昇順でソート
      if (a < b) return -1
      if (a > b) return 1

      return 0
    })
}
