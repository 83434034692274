import React from 'react'
import { Grid } from '@material-ui/core'
import { SearchForms } from './SearchForms'

export const Toolbar = (): JSX.Element => {
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={4}></Grid>
      <Grid item xs={8}>
        <SearchForms />
      </Grid>
    </Grid>
  )
}
