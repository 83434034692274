import React from 'react'
import { Grid } from '@material-ui/core'
import { DescriptionWithValue } from '../../../components/DescriptionWithValue'
import { usePrimeContractCountsHook } from '../../../../hooks/usePrimeContractCounts'
import { usePrimeServiceProjectHook } from '../../../../hooks/usePrimeServiceProject'

export const PrimeProjectCountItems = (): JSX.Element => {
  const isGslite = usePrimeServiceProjectHook().isGsLite
  const state = usePrimeContractCountsHook(isGslite)

  return (
    <Grid
      container
      justify="center"
      alignItems="flex-start"
      style={{ marginTop: '4px', marginBottom: '0px' }}
    >
      <Grid item xs={6}>
        <DescriptionWithValue
          firstCaption={'プロジェクト'}
          secondCaption={'登録数 / 上限数'}
          firstValue={state.totalProjects}
          secondValue={state.limitProjects}
          unit={'PJ'}
          isFetching={state.isFetching}
        />
      </Grid>
      <Grid item xs={6}>
        <DescriptionWithValue
          firstCaption={'プライムプロジェクト'}
          secondCaption={'選択数 / 上限数'}
          firstValue={state.totalSelectPrimeProjects}
          secondValue={state.limitPrimeProjects}
          unit={'PJ'}
          error={'超過しています。'}
          hasError={
            state.totalSelectPrimeProjects > state.limitPrimeProjects &&
            !state.isFetching
          }
          isFetching={state.isFetching}
        />
      </Grid>
    </Grid>
  )
}
