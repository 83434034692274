/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { NoDataInBox } from 'view/components/form/NoDataInBox'
import { OverDataInBox } from 'view/components/form/OverDataInBox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { RowData } from 'view/pages/companySelects/columns'
import { useHistory } from 'react-router-dom'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import CircularProgressFader from 'view/components/CircularProgressFader'
import { useCompaniesHook } from '../../../hooks/useCompanies'
import { LinkArrowButton } from '../../components/button/LinkArrowButton'
import { useAuth } from 'hooks/useAuth'

export const DataTable = ({ dataRows }: any): JSX.Element => {
  const tableHeight = 752
  const history = useHistory()
  const useStyles = makeStyles(() =>
    createStyles({
      rowHeight: {
        height: '64px',
      },
    }),
  )
  const classes = useStyles()
  const { isFetching } = useCompaniesHook()

  // 参照権限がないユーザの場合は404へ飛ばす
  const { isManager } = useAuth()
  if (!isManager) {
    history.push('/404')
  }

  if (isFetching) {
    return (
      <div style={{ height: tableHeight }}>
        <CircularProgressFader loading={isFetching} />
      </div>
    )
  }

  // 初期表示
  if (dataRows === undefined) {
    return <div style={{ height: tableHeight }}></div>
  }

  // 検索後0件
  if (dataRows.length === 0) {
    return (
      <div style={{ height: tableHeight }}>
        <NoDataInBox />
      </div>
    )
  }

  if (dataRows.length > 100) {
    return (
      <div style={{ height: tableHeight }}>
        <OverDataInBox />
      </div>
    )
  }

  return (
    <div style={{ height: tableHeight }}>
      <TableContainer style={{ maxHeight: '700px' }}>
        <Table stickyHeader>
          <TableHead style={{ height: '60px' }}>
            <TableRow>
              <TableCell style={{ width: '200px' }} align="center">
                企業ID
              </TableCell>
              <TableCell align="left">企業名</TableCell>
              <TableCell style={{ width: '50px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map((row: RowData) => (
              <TableRow key={row.companyId} className={classes.rowHeight}>
                <TableCell align="center">{row.companyId}</TableCell>
                <TableCell align="left">{row.companyName}</TableCell>
                <TableCell align="left">
                  <LinkArrowButton
                    to={`/companies/${row.companyId}/services`}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
