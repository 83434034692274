/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from '@material-ui/core'
import React from 'react'

export default ({ children, title, ...rest }: any): JSX.Element => {
  return (
    <Tooltip
      title={<span style={{ fontSize: '16px' }}>{title}</span>}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}
