/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'reducers/store'
import {
  initializeApi,
  updatePrimeServiceProjects,
} from 'reducers/projectPrimeServices/actions'
import {
  IPrimeServiceProjectsPostRequestBody,
  IPrimeProjectsPostRequest,
} from 'apis/repositories/primeProjectServices'
import { useParams, useHistory } from 'react-router-dom'
import {
  getServiceByName,
  primeServices,
} from '../view/pages/primeProjects/services'
import { useSnackbar } from 'notistack'
import { RowData } from '../view/pages/primeProjects/columns'
import { useEffect } from 'react'
import { UnauthorizedErrorStatus } from '../constants'

export const useUpdatePrimeServiceProjects = (): (() => void) => {
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const { companyId } = useParams<{ companyId: string }>()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // primeServiceProject の state が更新された時に
  // API のステータスをみてメッセージを出し分ける
  useEffect(() => {
    if (state.api.status === 200 && state.api.method === 'POST') {
      const message = 'プライムプロジェクトを登録しました。'
      enqueueSnackbar(message, { variant: 'success' })
      dispatch(initializeApi())
    }

    if (state.api.status !== 200 && state.api.status !== undefined) {
      if (state.api.status === UnauthorizedErrorStatus) {
        history.push('/auth')
      }
    }
  }, [state, enqueueSnackbar, history, dispatch])

  const update = () => {
    const body = getRequestBody(
      state.updateDatetime,
      state.isGsLite,
      state.rows,
      state.checkedProjectIds,
    )
    dispatch(updatePrimeServiceProjects(companyId, body))
  }

  return update
}

const getRequestBody = (
  updateDatetime: string,
  isGslite: boolean,
  rows: RowData[],
  checkedProjectIds: string[],
): IPrimeServiceProjectsPostRequestBody => {
  // 分割したrowのうち更に対象となるプロジェクトをフィルターする
  const filteredRows: RowData[] = rows.filter((row) =>
    checkedProjectIds.includes(row.projectId),
  )

  const primeProjects: IPrimeProjectsPostRequest[] = []
  for (const row of filteredRows) {
    for (const service of primeServices) {
      if (service.editable) {
        primeProjects.push({
          projectId: row.projectId,
          serviceId: getServiceByName(service.serviceName).serviceId,
          enabled: (row as any)[service.serviceName],
        })
      }
    }
  }
  const result: IPrimeServiceProjectsPostRequestBody = {
    updateDatetime,
    isGslite,
    primeProjects,
  }

  return result
}

/**
 *
 * @param rows
 * @param rowsPerPage
 * @param page
 * rowsのうち画面で表示しているプロジェクトのみを絞り込む
 */
export const slicePageRows = (
  rows: RowData[],
  rowsPerPage: number,
  page: number,
): RowData[] => {
  const startPrj = page * rowsPerPage
  const endPrj = (page + 1) * rowsPerPage

  return rows.slice(startPrj, endPrj)
}
