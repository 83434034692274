import React from 'react'
import Icon from 'assets/header_logo_project.png'
export const ServiceLogo = () => {
  return (
    <img
      src={Icon}
      style={{
        height: '32px',
      }}
      alt="logo"
    />
  )
}
