import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useUpdatePrimeServiceProjects } from '../../../../hooks/useUpdatePrimeServiceProjects'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/store'
import CircularProgressFader from 'view/components/CircularProgressFader'
import {
  HistoryBackButton,
  ConfirmButton,
  CsvDownloadButton,
} from 'view/components/button'
import { useAuth } from 'hooks/useAuth'

export const Footer = (): JSX.Element => {
  const updatePrimeServiceProjects = useUpdatePrimeServiceProjects()
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const { isHelpDesk, isManager } = useAuth()

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="center"
      style={{
        marginTop: '0px',
      }}
    >
      {isManager && (
        <Grid item>
          <HistoryBackButton />
        </Grid>
      )}
      {!isHelpDesk && (
        <Grid item>
          <ConfirmButton
            handleClick={() => updatePrimeServiceProjects()}
            disabled={!state.checkedProjectIds.length}
          />
        </Grid>
      )}
      <Grid item>
        <CsvDownloadButton
          isGslite={state.isGsLite}
          disabled={state.api.isUpdating}
        />
      </Grid>
      <CircularProgressFader loading={state.api.isUpdating} />
    </Grid>
  )
}
