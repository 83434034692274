import Repository from './repository'
import {
  PrimeServiceId,
  PrimeServiceNameJp,
} from 'view/pages/primeProjects/services'
import { AxiosResponse } from 'axios'
import * as querystring from 'querystring'

export interface IPrimeProject {
  branchId: string
  branchName: string
  projectId: string
  projectName: string
  projectInsertDatetime: string
  serviceId: PrimeServiceId
  serviceName: PrimeServiceNameJp
  enabled: boolean
}
export interface IPrimeServiceProjects {
  totalCount: number
  companyId: string
  companyName: string
  updateDatetime: string
  primeProjects: IPrimeProject[]
  pageTokens: string[]
}
export interface IPrimeServiceProjectsPostRequestBody {
  updateDatetime: string
  isGslite: boolean
  primeProjects: IPrimeProjectsPostRequest[]
}
export interface IPrimeProjectsPostRequest {
  projectId: string
  serviceId: string
  enabled: boolean
}
export interface IPrimeServiceProjectsPostResponse {
  updateDatetime: string
  primeProjects: IPrimeProjectsPostResponse[]
}
export interface IPrimeProjectsPostResponse {
  companyId: string
  projectId: string
  serviceId: string
  enabled: boolean
}

interface IDownload {
  fileName: string
  file: Blob
}

/**
 * プライムプロジェクト一覧取得APIのクエリストリング
 */
export interface IPrimeServiceProjectsQueryParams {
  branchName?: string
  projectId?: string
  projectName?: string
  from?: string
  to?: string
  serviceId?: string[]
  page?: number
  perPage?: number
  pageTokens?: string[]
  isGslite?: boolean
}

export default {
  /**
   * APIリクエストに指定するクエリストリングを組み立てる
   *
   * @param IQueryParams
   */
  assembleQuerystring(params: IPrimeServiceProjectsQueryParams): string {
    const serviceId = params.serviceId
      ? querystring.stringify({ 'serviceId[]': params.serviceId })
      : null
    delete params.serviceId

    if (params.branchName?.length === 0) delete params.branchName
    if (params.projectId?.length === 0) delete params.projectId
    if (params.projectName?.length === 0) delete params.projectName
    if (params.from?.length === 0) delete params.from
    if (params.to?.length === 0) delete params.to

    return querystring.stringify({ ...params }) + '&' + serviceId
  },

  async get(
    companyId: string,
    params: IPrimeServiceProjectsQueryParams,
  ): Promise<AxiosResponse<IPrimeServiceProjects>> {
    const qs = this.assembleQuerystring(params)

    return Repository.get<IPrimeServiceProjects>(
      `/companies/${companyId}/projects?${qs}`,
    )
  },

  async post(
    companyId: string,
    data: IPrimeServiceProjectsPostRequestBody,
  ): Promise<AxiosResponse<IPrimeServiceProjectsPostResponse>> {
    return Repository.post<IPrimeServiceProjectsPostResponse>(
      `/companies/${companyId}/projects`,
      data,
    )
  },

  async download(companyId: string, isGslite: boolean): Promise<IDownload> {
    const qs = querystring.stringify({ isGslite })
    const res = await Repository.get(
      `/companies/${companyId}/projects/download?${qs}`,
      { responseType: 'arraybuffer' },
    )
    const file = new Blob([res.data], { type: 'text/csv' })
    const contentDisposition = res.headers['content-disposition']
    const fileName = getFileName(contentDisposition)

    return { fileName, file }
  },
}

export const getFileName = (contentDisposition: string): string => {
  // ファイル名に必要な部分のみ切り取る
  let fileName = contentDisposition.substring(
    contentDisposition.indexOf('"') + 1,
    contentDisposition.length - 1,
  )
  //デコードするとスペースが"+"になるのでスペースへ置換する
  fileName = decodeURI(fileName).replace(/\+/g, ' ')

  return fileName
}
