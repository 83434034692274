import React from 'react'
import Pagination from './TablePagination'
import { NoDataInBox } from 'view/components/form/NoDataInBox'
import { usePrimeServiceProjectHook } from '../../../../hooks/usePrimeServiceProject'
import CircularProgressFader from 'view/components/CircularProgressFader'
import HorizontalScrollTable from './HorizontalScrollTable'

export const Table = (): JSX.Element => {
  const height = {
    table: 458,
    pagination: 32,
  }
  const state = usePrimeServiceProjectHook()

  const loading = state.api.isFetching
  if (loading) {
    return (
      <>
        <div style={{ height: height.table + 5 }}>
          <CircularProgressFader loading={loading} />
        </div>
        <Pagination />
      </>
    )
  }

  if (!state.rows.length) {
    return (
      <div style={{ height: height.table + height.pagination }}>
        <NoDataInBox />
      </div>
    )
  }

  return (
    <div>
      <HorizontalScrollTable rows={state.rows} height={height.table} />
      <Pagination />
    </div>
  )
}
