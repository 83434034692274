/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { Controller, Control } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import jaLocale from 'date-fns/locale/ja'
import format from 'date-fns/format'
import { INVALID_DATE } from 'messages'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/store'
import { ISearchCondition } from 'reducers/projectPrimeServices/types'
import { projectSearchConditions } from '../../pages/primeProjects/PrimeProjectsPage'

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: any) {
    return format(date, 'yyyy MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: any) {
    return format(date, 'MMMd日', { locale: this.locale })
  }
}

const getOneMonthAgo = (): Date => {
  const dt = new Date()
  dt.setMonth(dt.getMonth() - 1)

  return dt
}
const toDate = (str: string): Date => {
  const arr: any[] = (
    str.substr(0, 4) +
    '/' +
    str.substr(4, 2) +
    '/' +
    str.substr(6, 2)
  ).split('/')

  return new Date(arr[0], arr[1] - 1, arr[2])
}

export type Props = {
  control: Control<Record<string, any>>
  fromName: string
  toName: string
}
/**
 * 登録日（from）、登録日（to）検索フォーム
 *
 * 初期値として from に現在日付の１ヶ月前、to に現在日付を入力した状態のフォームコンポーネント
 * props に React Hook Form の control プロパティを渡すことで、バリデーションおよびフォームタグに組み込む用途を想定している。
 */
export const FromToForm = ({ control, fromName, toName }: Props) => {
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const condition: ISearchCondition = projectSearchConditions(state)

  const today = new Date()
  const [to, setTo] = useState<Date | null>(today)

  const oneMonthAgo = getOneMonthAgo()
  const [from, setFrom] = useState<Date | null>(oneMonthAgo)

  return (
    <>
      <Typography style={{ marginRight: '5px' }}>登録日</Typography>
      <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
        <Controller
          as={
            <KeyboardDatePicker
              style={{ width: '160px' }}
              value={from}
              onChange={setFrom}
              format="yyyy/MM/dd"
              okLabel="決定"
              cancelLabel="キャンセル"
              showTodayButton
              todayLabel="本日"
              invalidDateMessage={INVALID_DATE}
              data-testid={`keyboard-date-picker-from`}
            />
          }
          name={fromName}
          control={control}
          defaultValue={
            condition.insertDateFrom ? toDate(condition.insertDateFrom) : from
          }
        />
        <Typography style={{ marginLeft: '5px', marginRight: '5px' }}>
          〜
        </Typography>
        <Controller
          as={
            <KeyboardDatePicker
              style={{ width: '160px' }}
              value={to}
              onChange={setTo}
              format="yyyy/MM/dd"
              okLabel="決定"
              cancelLabel="キャンセル"
              showTodayButton
              todayLabel="本日"
              invalidDateMessage={INVALID_DATE}
              data-testid={`keyboard-date-picker-to`}
            />
          }
          name={toName}
          control={control}
          defaultValue={
            condition.insertDateTo ? toDate(condition.insertDateTo) : today
          }
        />
      </MuiPickersUtilsProvider>
    </>
  )
}
