/* eslint-disable @typescript-eslint/no-explicit-any */
import { RowData, ColumnName } from 'view/pages/primeProjects/columns'
import { primeServices } from 'view/pages/primeProjects/services'

export class RowController {
  public rows: RowData[]
  constructor(rows: RowData[]) {
    this.rows = rows
  }

  /**
   * どれか１つでも行にチェックが入っているか判定する
   * cacicar は対象外
   * @param id
   */
  evenOneCheckForRow = (id: string): boolean => {
    for (const { serviceName } of primeServices) {
      if (serviceName !== 'cacicar') {
        if (this.isSelectedFor(id, serviceName)) return true
      }
    }

    return false
  }

  /**
   * 指定したセルにチェックが入っているか判定する
   * @param id 行のID（プロジェクトID）
   * @param columnName 列の名前（カラム名）
   */
  isSelectedFor = (id: string, columnName: ColumnName): boolean => {
    const one: any = this.rows.find((row) => row.projectId === id)

    return one[columnName]
  }
}
