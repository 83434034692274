/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ICompanySelectsAction,
  ICompanySelectsActionType as ActionType,
} from './types'
import { ICompany } from 'apis/repositories/company'
import { ISearchParams } from 'view/pages/companySelects/SearchForms'

export interface ICompaniesState {
  /** APIの取得リクエスト中ステータス */
  isFetching: boolean
  /** 取得件数 */
  totalCount: number
  /** 元請企業取得結果 */
  primeCompanies?: ICompany[]
  /** APIリクエストエラー */
  error?: any
  /** 検索条件 */
  searchCondition: ISearchParams
}

export const initialState = {
  isFetching: false,
  totalCount: 0,
  searchCondition: {},
}
/**
 * 元請企業取得結果を保持する state を更新する reducer
 * dispatch時にコールバックされる
 * @param state
 * @param action
 */
const reducer = (
  state: ICompaniesState = initialState,
  action: ICompanySelectsAction,
): ICompaniesState => {
  switch (action.type) {
    case ActionType.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ActionType.FETCH_SUCCEED:
      return {
        ...state,
        isFetching: false,
        totalCount: action.totalCount!,
        primeCompanies: action.primeCompanies!,
        searchCondition: action.searchCondition,
      }
    case ActionType.FETCH_FAILED:
      return {
        ...state,
        error: action.error,
      }
    default:
      // 初期化時はここに来る（initialStateのオブジェクトが返却される）
      return state
  }
}

export default reducer
