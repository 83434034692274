import { Fade, CircularProgress } from '@material-ui/core'
import React from 'react'

export default ({ loading }: { loading: boolean }): JSX.Element => {
  return (
    <Fade in={loading} style={{ transitionDelay: loading ? '500ms' : '0ms' }}>
      <CircularProgress />
    </Fade>
  )
}
