/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Switch, Route } from 'react-router-dom'
import { PrivateRoute } from '../../components/router/PrivateRoute'
import { Container, Typography, Grid } from '@material-ui/core'
import { RedirectToNotFound } from '../../components/router/RedirectToNotFound'
import Header from '../../components/header/Header'
import Home from '../Home'
import { Footer } from './Footer'
import { PrimeProjectsPage } from '../primeProjects/PrimeProjectsPage'
import { SelectsCompanyPage } from '../companySelects/CompanySelectsPage'

export const routes = [
  { path: '/', name: 'home', Component: Home },
  {
    path: '/companies/:companyId/services',
    name: 'プライムサービスプロジェクト選択',
    Component: PrimeProjectsPage,
  },
  {
    path: '/companies',
    name: '企業検索画面',
    Component: SelectsCompanyPage,
  },
]

const PageTitle = (props: any) => {
  const { title } = props

  return <Typography style={{ fontSize: 16 }}>{title}</Typography>
}

const Content = () => {
  return (
    <main>
      <Container maxWidth="xl">
        <Switch>
          {routes.map(({ path, name, Component }, key) => (
            <PrivateRoute exact key={key} path={path}>
              <Grid container spacing={3}>
                <Grid container item xs={12} style={{ paddingBottom: '0px' }}>
                  <PageTitle title={name} />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '0px' }}>
                  <Component />
                </Grid>
              </Grid>
            </PrivateRoute>
          ))}
          <Route>
            <RedirectToNotFound />
          </Route>
        </Switch>
      </Container>
    </main>
  )
}

export const Main = (): JSX.Element => {
  return (
    <>
      <Header display={true} />
      <Content />
      <Footer />
    </>
  )
}
