/* eslint-disable @typescript-eslint/no-explicit-any */
export const ICompanySelectsActionType = {
  /** APIから取得処理を開始 */
  FETCH_REQUEST: 'FETCH_COMPANY_REQUEST',
  /** APIからの取得処理が成功 */
  FETCH_SUCCEED: 'FETCH_COMPANY_SUCCEED',
  /** APIからの取得処理が失敗 */
  FETCH_FAILED: 'FETCH_COMPANY_FAILED',
  /** 検索条件を保持する */
  SEARCH_CLICK_FOR: 'SEARCH_CLICK_FOR_COMPANY',
}

export interface ICompanySelectsAction {
  type: (typeof ICompanySelectsActionType)[keyof typeof ICompanySelectsActionType]
  error?: any
  totalCount?: number
  primeCompanies?: any[]
  searchCondition?: any
}
