import React from 'react'
import Button from '@material-ui/core/Button'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

const ManualLinkButton = (): JSX.Element => {
  return (
    <Button
      variant="outlined"
      color="inherit"
      onClick={() =>
        window.open(
          'https://manual.kensetsu-site.com/gs/manual/manual-273/manual-9031',
        )
      }
      startIcon={<ImportContactsIcon />}
      size="small"
    >
      使い方
    </Button>
  )
}

export default ManualLinkButton
