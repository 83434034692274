/* eslint-disable @typescript-eslint/no-explicit-any */
import companySelectsAPIClient, { ICompany } from 'apis/repositories/company'
import {
  ICompanySelectsActionType as ActionType,
  ICompanySelectsAction as Action,
} from './types'
import { ISearchParams } from 'view/pages/companySelects/SearchForms'
import getLogger from '../../logger'

const logger = getLogger('@companySelects')

/**
 * APIリクエストを開始する Action Creator
 */
export const requestAction = (): Action => ({
  type: ActionType.FETCH_REQUEST,
})

/**
 * APIリクエストの成功時、取得したデータを store に保持するための Action Creator
 * @param rows
 */
type Props = {
  totalCount: number
  primeCompanies: ICompany[]
  searchCondition: ISearchParams
}

export const succeedAction = (props: Props): Action => ({
  type: ActionType.FETCH_SUCCEED,
  ...props,
})

/**
 * APIリクエストの失敗時、返却されたエラーを store に保持するための Action Creator
 * @param error
 */
export const failedAction = (error: any): Action => ({
  type: ActionType.FETCH_FAILED,
  error,
})

/**
 * 元請企業を取得する Async Action Creator
 *
 */
export const thunkedFetchCompaniesAction = (searchCondition: ISearchParams) => {
  logger.info('検索条件', searchCondition)

  return (dispatch: (arg: Action) => void) => {
    dispatch(requestAction())

    return companySelectsAPIClient
      .getMany(searchCondition)
      .then(({ data }) => {
        const param = {
          ...data,
          searchCondition,
        }
        dispatch(succeedAction(param))
      })
      .catch((error) => {
        logger.error(
          'プライムプロジェクト取得APIリクエストが失敗しました。',
          error,
        )
        dispatch(failedAction(error))
      })
  }
}
