import React from 'react'
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  Container,
  Button,
} from '@material-ui/core'
import Header from '../components/header/Header'
import { Footer } from '../pages/layout/Footer'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: 1400,
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export const LogoutPage = (): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <Paper elevation={0} className={classes.paper}>
          <Grid
            container
            spacing={5}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <div className={classes.root}>
                <Typography variant="h4">
                  ご利用ありがとうございました
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                size="medium"
                variant="contained"
                className={classes.button}
                onClick={() => window.close()}
                startIcon={<CloseIcon />}
              >
                閉じる
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}
