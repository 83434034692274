import React, { useEffect } from 'react'
import { useAuth } from 'hooks/useAuth'

const Auth = (): JSX.Element => {
  const { authorize } = useAuth()
  useEffect(() => {
    authorize()
  }, [authorize])

  return <div></div>
}

export default Auth
