/* eslint-disable @typescript-eslint/no-explicit-any */
import primeContractCountsAPIClient from 'apis/repositories/primeContractCounts'
import {
  IPrimeContractCountsActionType as ActionType,
  IPrimeContractCountsAction as Action,
  IPrimeContractCounts,
} from './types'
import getLogger from '../../logger'

const logger = getLogger('@contractsCount')

export const contractInitialize = (): Action => ({
  type: ActionType.INITIALIZE,
})

/**
 * APIリクエストを開始する Action Creator
 */
export const request = (): Action => ({
  type: ActionType.FETCH_REQUEST,
})

/**
 * APIリクエストの成功時、取得したデータを store に保持するための Action Creator
 * @param rows
 */
type Props = {
  normalProjects?: IPrimeContractCounts
  liteProjects?: IPrimeContractCounts
}

export const succeed = (props: Props): Action => ({
  type: ActionType.FETCH_SUCCEED,
  ...props,
})

/**
 * APIリクエストの失敗時、返却されたエラーを store に保持するための Action Creator
 * @param error
 */
export const failed = (error: any): Action => ({
  type: ActionType.FETCH_FAILED,
  error,
})

/**
 * 企業ごとのプロジェクト登録数、プライムプロジェクト選択数、上限数を取得する Async Action Creator
 *
 */
export const fetchPrimeContractCounts = (
  companyId: string,
  isGslite: boolean,
) => {
  return (dispatch: (arg: Action) => void) => {
    dispatch(request())

    return primeContractCountsAPIClient
      .get(companyId, isGslite)
      .then(({ data }) => {
        const contractsCount = isGslite
          ? { liteProjects: { ...data } }
          : { normalProjects: { ...data } }
        dispatch(succeed(contractsCount))
      })
      .catch((error) => {
        const msg = 'プライムプロジェクト件数取得APIリクエストが失敗しました。'
        logger.error(msg, error)
        dispatch(failed(error))
      })
  }
}
