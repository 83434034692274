export const ManagerType = {
  /** 1: 一般ユーザ */
  User: '1',
  /** 2: 企業担当者 */
  CompanyChief: '2',
  /** 3: 企業サブ担当者 */
  CompanySubChief: '3',
  /** 4: システム管理者 */
  Administrator: '4',
  /** 5: ヘルプデスク */
  HelpDesk: '5',
  /** 6: MCDP運用担当者 */
  McdpOperator: '6',
}
export type ManagerType = typeof ManagerType[keyof typeof ManagerType]

interface IManagerType {
  type: string
  typeName: string
}

export const ManagerTypes: IManagerType[] = [
  {
    type: '1',
    typeName: '一般ユーザ',
  },
  {
    type: '2',
    typeName: '企業担当者',
  },
  {
    type: '3',
    typeName: '企業サブ担当者',
  },
  {
    type: '4',
    typeName: 'システム管理者',
  },
  {
    type: '5',
    typeName: 'ヘルプデスク',
  },
  {
    type: '6',
    typeName: 'MCDP運用担当者',
  },
]

export const UnauthorizedErrorStatus = 401
export const ForbiddenErrorStatus = 403
export const NotFoundErrorStatus = 404
