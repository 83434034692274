/* eslint-disable @typescript-eslint/no-explicit-any */
export const IPrimeContractCountsActionType = {
  /** stateを初期化 */
  INITIALIZE: 'INITIALIZE_CONTRACT_COUNT',
  /** APIから取得処理を開始 */
  FETCH_REQUEST: 'FETCH_CONTRACT_COUNT_REQUEST',
  /** APIからの取得処理が成功 */
  FETCH_SUCCEED: 'FETCH_CONTRACT_COUNT_SUCCEED',
  /** APIからの取得処理が失敗 */
  FETCH_FAILED: 'FETCH_CONTRACT_COUNT_FAILED',
}

export interface IPrimeContractCountsAction {
  type: (typeof IPrimeContractCountsActionType)[keyof typeof IPrimeContractCountsActionType]
  error?: any
  normalProjects?: IPrimeContractCounts
  liteProjects?: IPrimeContractCounts
}

export interface IPrimeContractCounts {
  primeLimitCount?: number | null
  primeSelectCount?: number | null
  projectRegisteredCount?: number | null
  projectLimitCount?: number | null
}

export interface IPrimeContractCountsState {
  /** APIの取得リクエスト中ステータス */
  isFetching: boolean
  /** プライムプロジェクト(通常)契約件数情報 */
  normalProjects: IPrimeContractCounts
  /** プライムプロジェクト(lite)契約件数情報 */
  liteProjects: IPrimeContractCounts
  /** APIリクエストエラー */
  error?: any
}
