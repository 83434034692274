import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'reducers/store'
import { IPrimeServiceProjectState } from 'reducers/projectPrimeServices/types'
import { useHistory } from 'react-router-dom'
import { checkResponseError } from 'view/components/checkError'

/**
 * プライムプロジェクトの一覧をReduxストアから取得して検索するカスタムHooks
 */
export const usePrimeServiceProjectHook = (): IPrimeServiceProjectState & {
  pageIndex: { first: number; last: number }
} => {
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const history = useHistory()
  const dispatch = useDispatch()

  checkResponseError(history, dispatch, state)

  const pageIndex = {
    first: state.page * state.perPage,
    last: state.page * state.perPage + state.perPage,
  }

  return {
    ...state,
    pageIndex,
  }
}
