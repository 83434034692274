// TypeScript3.4から導入された const assertions を使用する。
// Union Typeの種類が増えるたびに配列を編集するだけで良いし、Union型の type でループ処理が実現できるようになる。
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions

export const primeServices = [
  {
    serviceId: '0000110',
    serviceName: 'worksite',
    label: 'ワークサイト',
    editable: true,
  },
  {
    serviceId: '0000130',
    serviceName: 'skillMap',
    label: 'スキルマップサイト',
    editable: true,
  },
  {
    serviceId: '0000140',
    serviceName: 'cacicar',
    label: 'cacicar for 建設',
    editable: false,
  },
  {
    serviceId: '0000150',
    serviceName: 'ccus',
    label: 'CCUSデータ連携サービス',
    editable: false,
  },
] as const

type PrimeService = typeof primeServices[number]

/**
 * プライムサービス名称
 */
export type PrimeServiceName = PrimeService['serviceName']
/**
 * プライムサービスID
 */
export type PrimeServiceId = PrimeService['serviceId']

/**
 * プライムサービス名（日本語）
 */
export type PrimeServiceNameJp = PrimeService['label']

export const getServiceByName = (name: PrimeServiceName): PrimeService => {
  return primeServices.find((item) => item.serviceName === name)!
}
