/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  FormGroup,
  Checkbox,
  Grid,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { FormControlCheckBox } from './FormControlCheckBox'
import { Control, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'reducers/store'
import { expandSearchPanel } from 'reducers/projectPrimeServices/actions'
import { primeServices } from 'view/pages/primeProjects/services'
import { FromToForm } from 'view/components/form/FromToForm'
import { ISearchCondition } from 'reducers/projectPrimeServices/types'
import { projectSearchConditions } from '../PrimeProjectsPage'

export type Props = {
  control: Control<Record<string, any>>
}

/**
 * 絞り込み検索条件入力フォーム
 *
 * 以下、入力の有無に対して絞り込み条件を入力する
 * - 登録日（from）
 * - 登録日（to）
 * - 各サービスのチェックボックス
 *
 * トグルボタンをクリックすることで、絞り込み条件入力フォームは開閉する
 */
export const FilterSearchForms = (props: Props): JSX.Element => {
  const { control } = props

  const state = useSelector((state: AppState) => state.primeServiceProject)
  const dispatch = useDispatch()
  const condition: ISearchCondition = projectSearchConditions(state)

  return (
    <ExpansionPanel
      expanded={state.searchConditionExpanded}
      style={{
        border: 'thin solid rgba(195, 195, 195)',
      }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
        onClick={() => dispatch(expandSearchPanel())}
        data-testid="expansion-panel-summary"
      >
        <Typography align="center" color="primary">
          絞り込み条件を追加
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <FormControl component="fieldset">
          <FormGroup row>
            <FromTo {...props} />
            {primeServices.map((service) => {
              if (service.serviceName === 'cacicar') return null
              const checked = condition.services?.includes(service.serviceId)
                ? true
                : false

              return (
                <FormControlCheckBox
                  key={service.serviceName}
                  value={service.serviceName}
                  label={service.label}
                  control={control}
                  checked={checked}
                />
              )
            })}
          </FormGroup>
        </FormControl>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

/**
 * チェックボックス付き登録日（from）、登録日（to）検索フォーム
 * チェックボックスの値が true の場合、登録日（from）、登録日（to）検索フォームの入力内容を検索条件に加える。チェックボックスの値が false の場合、登録日（from）、登録日（to）検索フォームに入力されていても、検索条件に加えない。
 */
const FromTo = ({ control }: Props) => {
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const condition: ISearchCondition = projectSearchConditions(state)

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Controller
        as={<Checkbox color="primary" />}
        name={'insertDateChecked'}
        defaultValue={condition.insertDateChecked || false}
        control={control}
      />
      <FromToForm
        control={control}
        fromName="insertDateFrom"
        toName="insertDateTo"
      />
    </Grid>
  )
}
