import React, { useEffect } from 'react'
import { useAuth } from 'hooks/useAuth'

const Callback = (): JSX.Element => {
  const { callback } = useAuth()
  useEffect(() => {
    callback()
  }, [callback])

  return <p></p>
}

export default Callback
