/* eslint-disable @typescript-eslint/no-explicit-any */
import { RowData, CheckableColumnName } from 'view/pages/primeProjects/columns'

export const IPrimeServiceProjectActionType = {
  /** stateを初期化 */
  INITIALIZE: 'INITIALIZE_PRIME_SERVICE_PROJECTS',
  /** api の stateを初期化 */
  INITIALIZE_API: 'INITIALIZE_API_PRIME_SERVICE_PROJECTS',
  /** APIから取得処理を開始 */
  FETCH_REQUEST: 'FETCH_REQUEST_PRIME_SERVICE_PROJECTS',
  /** APIからの取得処理が成功 */
  FETCH_SUCCEED: 'FETCH_SUCCEED_PRIME_SERVICE_PROJECTS',
  /** APIからの取得処理が失敗 */
  FETCH_FAILED: 'FETCH_FAILED_PRIME_SERVICE_PROJECTS',
  /** ページあたりのチェックボックスすべて ON/OFF を切り替える */
  SELECT_ALL_CLICK_FOR: 'SELECT_ALL_CLICK_FOR_PRIME_SERVICE_PROJECTS',
  /** 選択したチェックボックスすべて ON/OFF を切り替える */
  SELECT_CLICK_FOR: 'SELECT_CLICK_FOR_PRIME_SERVICE_PROJECTS',
  /** ページ番号を更新する */
  UPDATE_PAGE: 'UPDATE_PAGE_PRIME_SERVICE_PROJECTS',
  /** １ページあたりの表示件数を変更する */
  UPDATE_ROWS_PER_PAGE: 'UPDATE_ROWS_PER_PAGE_PRIME_SERVICE_PROJECTS',
  /** 検索条件を保持する */
  SEARCH_CLICK_FOR: 'SEARCH_CLICK_FOR_PRIME_SERVICE_PROJECTS',
  /** Liteプロジェクトの検索条件を保持する */
  LITE_SEARCH_CLICK_FOR: 'LITE_SEARCH_CLICK_FOR_PRIME_SERVICE_PROJECTS',
  /** 検索条件ウィンドウを開閉する */
  EXPAND_SEARCH_PANEL: 'EXPAND_SEARCH_PANEL_FOR_PRIME_SERVICE_PROJECTS',
  /** GS-Liteプロジェクトのタブを切り替える */
  CHANGE_PROJECT_TAB: 'CHANGE_PROJECT_TAB_FOR_PRIME_SERVICE_PROJECTS',
  /** 登録APIを開始 */
  UPDATE_REQUEST: 'UPDATE_REQUEST_PRIME_SERVICE_PROJECTS',
  /** 登録APIが成功 */
  UPDATE_SUCCEED: 'UPDATE_SUCCEED_PRIME_SERVICE_PROJECTS',
  /** 登録APIが失敗 */
  UPDATE_FAILED: 'UPDATE_FAILED_PRIME_SERVICE_PROJECTS',
}

/**
 * プライムプロジェクト選択画面 検索条件
 */
export interface ISearchCondition {
  branchName?: string
  projectId?: string
  projectName?: string
  insertDateChecked?: boolean
  insertDateFrom?: string
  insertDateTo?: string
  services?: string[]
}

export interface IPrimeServiceProjectState {
  /** ログインユーザの所属 元請企業名 */
  companyName: string
  /** 元請企業に紐づくプロジェクトの総数 */
  totalCount: number
  /** プロジェクトIDごとのプライムサービス選択情報 */
  rows: RowData[]
  /** プライムサービスが選択されているプロジェクトID（APIで最初に取得した値） */
  initialSelectedProjectIds: string[]
  /** プライムサービスが選択されているプロジェクトID（ユーザ操作により変化した値） */
  selectedProjectIds: string[]
  /** チェックボックスを操作したプロジェクトID */
  checkedProjectIds: string[]
  /** 当該企業に属するプライムサービスの中で最新の更新日時*/
  updateDatetime: string
  /** ページ番号 */
  page: number
  /** ページあたりの表示件数 */
  perPage: number
  /** APIリクエスト */
  api: {
    /** API メソッドの種類 */
    method?: 'GET' | 'POST'
    /** APIレスポンスステータス */
    status?: number
    /** APIリクエストエラー */
    error?: any
    /** APIの取得リクエスト中ステータス */
    isFetching: boolean
    /** 登録APIのリクエスト中ステータス */
    isUpdating: boolean
  }
  /** GS-Liteプロジェクト選択画面かどうか */
  isGsLite: boolean
  /** 検索条件 */
  searchCondition: ISearchCondition
  /** GS-Liteプロジェクトの検索条件 */
  liteSearchCondition: ISearchCondition
  /** 検索条件プルダウンの開閉状態 */
  searchConditionExpanded: boolean
  /** 各ページのページトークン */
  pageTokens: string[]
}

export interface IPrimeServiceProjectAction {
  type: (typeof IPrimeServiceProjectActionType)[keyof typeof IPrimeServiceProjectActionType]
  companyName?: string
  totalCount?: number
  rows?: RowData[]
  selectedProjectIds?: string[]
  updateDatetime?: string
  page?: number
  apiStatus?: number
  perPage?: number
  error?: any
  projectId?: string
  columnName?: CheckableColumnName
  first?: number
  last?: number
  checked?: boolean
  searchCondition?: ISearchCondition
  liteSearchCondition?: ISearchCondition
  isGsLite?: boolean
  pageTokens?: string[]
}
