/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUser } from 'apis/repositories/auth'
import { AxiosError } from 'axios'

const LoginActionType = {
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  LOGIN_SUCCEED: 'LOGIN_SUCCEED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
}

interface IAuthenticateAction {
  type: (typeof LoginActionType)[keyof typeof LoginActionType]
  user: IUser | null
  error: AxiosError | null
}

export const loginSucceedAction = (user: IUser): IAuthenticateAction => {
  return { type: LoginActionType.LOGIN_SUCCEED, user, error: null }
}

export const loginFailedAction = (error: any): IAuthenticateAction => {
  return { type: LoginActionType.LOGIN_FAILED, user: null, error }
}

export const requestLoginAction = () => {
  return { type: LoginActionType.REQUEST_LOGIN, user: null, error: null }
}

export const logoutAction = (): IAuthenticateAction => {
  return { type: LoginActionType.LOGOUT, user: null, error: null }
}

export interface IAuthState {
  isFetching: boolean
  user: IUser | null
  error: AxiosError | null
}

const initialState = { isFetching: false, user: null, error: null }

const me = (
  state: IAuthState = initialState,
  action: IAuthenticateAction,
): IAuthState => {
  switch (action.type) {
    case LoginActionType.REQUEST_LOGIN:
      return {
        ...state,
        isFetching: true,
        user: action.user,
        error: action.error,
      }
    case LoginActionType.LOGIN_SUCCEED:
      // loginUserInfo というキー の cookie を axiosInterceptor.ts でセットすることで認証情報を保持している。
      return {
        ...state,
        isFetching: false,
        user: action.user,
        error: action.error,
      }
    case LoginActionType.LOGIN_FAILED:
      return {
        ...state,
        isFetching: false,
        user: action.user,
        error: action.error,
      }
    case LoginActionType.LOGOUT:
      // loginUserInfo というキー の cookie を 削除することで認証状態を破棄する
      document.cookie = 'loginUserInfo=; max-age=0'

      return {
        ...state,
        isFetching: false,
        user: null,
        error: null,
      }
    default:
      return state
  }
}

export default me
