/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import Autocomplete, {
  AutocompleteRenderGroupParams,
} from '@material-ui/lab/Autocomplete'
import ListSubheader from '@material-ui/core/ListSubheader'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Controller, Control } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { AppState } from 'reducers/store'
import { ISearchCondition } from 'reducers/projectPrimeServices/types'
import { projectSearchConditions } from '../../pages/primeProjects/PrimeProjectsPage'

const LISTBOX_PADDING = 8

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props

  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  })
}

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)

  return <div ref={ref} {...props} {...outerProps} />
})

const OuterElementContext = React.createContext({})

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
]

const Listbox = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }

    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])

  return ref
}

type ContrrolledAutoCompleteStyledTextFieldProps = {
  label: string
  options: string[]
  name: string
  control: Control<Record<string, any>>
}

/**
 * オートサジェスト付きテキストボックスを React Hook Form で制御可能にしたコンポーネント
 * useForm から呼び出された control オブジェクトを渡して制御する
 */
export const ControlledAutoCompleteStyledTextField = (
  props: ContrrolledAutoCompleteStyledTextFieldProps,
): JSX.Element => {
  const { label, options, name, control } = props
  const state = useSelector((state: AppState) => state.primeServiceProject)
  const condition: ISearchCondition = projectSearchConditions(state)
  const branchName = condition.branchName

  return (
    <Controller
      as={
        <AutoCompleteStyledTextField
          label={label}
          options={options}
          onChange={(value: string) => value}
          value={branchName}
        />
      }
      name={name}
      control={control}
      onChange={([, data]) => data}
      defaultValue={branchName}
    />
  )
}

/**
 * オートサジェスト付きテキストボックス
 */
export const AutoCompleteStyledTextField = ({
  label,
  options,
  onChange,
  value,
}: {
  label: string
  options: string[]
  onChange: any
  value: string | undefined
}): JSX.Element => {
  return (
    <Autocomplete
      getOptionSelected={(option, value) => option === value || option !== ''}
      disableListWrap
      renderGroup={renderGroup}
      options={options}
      ListboxComponent={
        Listbox as React.ComponentType<React.HTMLAttributes<HTMLElement>>
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          fullWidth={true}
          name={label}
          data-testid={`text-field-${label}`}
        />
      )}
      renderOption={(option: any) => <Typography noWrap>{option}</Typography>}
      onChange={onChange}
      value={value ? String(value) : ''}
    />
  )
}
