export const palette = {
  primary: {
    dark: '#114285',
    main: 'rgb(25,95,190)',
    light: '#477fcb',
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  // secondary は使用する予定がないということだが、仮で入れておく
  secondary: {
    dark: '#007bb2',
    main: '#00b0ff',
    light: '#33bfff',
  },
}
