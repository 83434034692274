/* eslint-disable @typescript-eslint/no-empty-interface */
import repository from './repository'
import { AxiosResponse } from 'axios'

export interface IAuthLogin {
  authorizationUrl: string
}

export interface ILogout {
  // no responseBody
}

export interface IUser {
  mcdpId: string
  lastName: string
  firstName: string
  companyId: string
  companyName: string
  managerType: string
}

export default {
  async auth(): Promise<AxiosResponse<IAuthLogin>> {
    return repository.get<IAuthLogin>('/auth')
  },
  async callBack(params: string): Promise<AxiosResponse<IUser>> {
    return repository.get<IUser>('/auth/callback' + params)
  },
  async currentUser(): Promise<AxiosResponse<IUser>> {
    return repository.get<IUser>('/me')
  },
  async logout(): Promise<AxiosResponse<ILogout>> {
    return repository.post<ILogout>('/logout')
  },
}
