import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { DataTable } from './Table'
import { Toolbar } from './ToolBar'
import { Footer } from './Footer'
import { useCompaniesHook } from '../../../hooks/useCompanies'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: 1400,
  },
}))

export const SelectsCompanyPage = (): JSX.Element => {
  const classes = useStyles()
  const state = useCompaniesHook()
  const rows = state.primeCompanies

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Toolbar />
        <DataTable dataRows={rows} />
        <Footer />
      </Paper>
    </div>
  )
}
