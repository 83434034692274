import { useSelector } from 'react-redux'
import { AppState } from 'reducers/store'
import { ICompaniesState } from 'reducers/companySelectsServices/reducer'
import { checkResponseError } from 'view/components/checkError'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

/**
 * 元請企業一覧をReduxストアから取得するカスタムHooks
 */
export const useCompaniesHook = (): ICompaniesState => {
  const state = useSelector((state: AppState) => state.companies)
  const history = useHistory()
  const dispatch = useDispatch()
  checkResponseError(history, dispatch, state)

  return state
}
