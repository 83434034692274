/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

const redirectToLogin = (location: any): React.ReactNode => {
  // 未認証の場合は '/auth' にリダイレクトさせ、SSO認証のフローを行う

  return <Redirect to={{ pathname: '/auth', state: { from: location } }} />
}

export const PrivateRoute = ({ children, ...rest }: any): JSX.Element => {
  const { user } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) => (user ? children : redirectToLogin(location))}
    />
  )
}
