/* eslint-disable @typescript-eslint/no-explicit-any */
import MockAdapter from 'axios-mock-adapter'
import { AxiosInstance } from 'axios'
import { getPrimeProjects } from './primeProjects.mock'
import primeContractCounts from './primeContractCounts.mock'
import { getCompanies } from './company.mock'
import branches from './branches.mock'
import {
  authResponse,
  authCallbackResponse,
  authResponseHeader,
  authCallbackResponseHeader,
  clearCodeVerifierResponseHeader,
  clearLoginUserInfoResponseHeader,
} from './auth.mock'

const route = (path: string): any => {
  return new RegExp('^' + path.replace(/:\w+/g, '[^/]+'))
}
const header = { 'Set-Cookie': 'TODO' }

const whichResponse = (res: { code: string; message: string } | any) => {
  switch (res.code) {
    case 'UNAUTHORIZED':
      return [403, res, header]
    case 'FORBIDDEN':
      return [403, res, header]
    case 'NO_DATA':
      return [404, res, header]
    case 'EXCLUSIVE ERROR':
      return [409, res, header]
    case 'INTERNAL_SERVER_ERROR':
      return [500, res, header]
    default:
      return [200, res, header]
  }
}

export default {
  run: (client: AxiosInstance): void => {
    const mock = new MockAdapter(client)

    const callBackRegex = route(`/auth/callback`)
    mock.onGet(callBackRegex).reply(() => {
      return [200, authCallbackResponse, authCallbackResponseHeader]
    })

    const mePath = route('/me')
    mock.onGet(mePath).reply(() => {
      return [200, authCallbackResponse, authCallbackResponseHeader]
    })

    const getAuthPath = route('/auth')
    mock.onGet(getAuthPath).reply(() => {
      return [200, authResponse, authResponseHeader]
    })

    const getLogoutPath = route('/logout')
    mock.onGet(getLogoutPath).reply(() => {
      return [
        200,
        '',
        clearCodeVerifierResponseHeader,
        clearLoginUserInfoResponseHeader,
      ]
    })

    const getContractsCountPath = route(`/companies/:companyId/contracts/count`)
    mock.onGet(getContractsCountPath).reply(() => {
      return [200, primeContractCounts, header]
    })

    const primeProjectsPath = route('/companies/:companyId/projects')
    mock.onGet(primeProjectsPath).reply((config) => {
      const companyId = config.url!.split('/')[2]
      const res = getPrimeProjects(companyId)

      return whichResponse(res)
    })

    const branchesPath = route('/companies/:companyId/branches')
    mock.onGet(branchesPath).reply(() => {
      return [200, branches, header]
    })

    const getCompaniesPath = route(`/companies`)
    mock.onGet(getCompaniesPath).reply((config) => {
      const res = getCompanies(
        config.params.companyId,
        config.params.companyName,
      )

      return whichResponse(res)
    })

    mock.onPost(primeProjectsPath).reply((config) => {
      const companyId = config.url!.split('/')[2]

      if (companyId === '0000000001') {
        return [
          400,
          {
            code: 'BAD_REQUEST',
            message: 'リクエストが不正です。',
          },
          header,
        ]
      }

      return [
        200,
        {
          updateDatetime: '2020-04-22 09:00:00.000000',
          primeProjects: [
            {
              companyId,
              projectId: 'MOCK001',
              serviceId: 'MOCK001',
              enabled: true,
            },
            {
              companyId,
              projectId: 'MOCK001',
              serviceId: 'MOCK002',
              enabled: true,
            },
          ],
        },
        header,
      ]
    })
  },
}
