/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button } from '@material-ui/core'

export const SearchButton = (props: any): JSX.Element => {
  const { onClick, ...rest } = props

  return (
    <Button
      data-testid="search-button"
      type="submit"
      variant="contained"
      color="primary"
      onClick={onClick}
      {...rest}
    >
      検索
    </Button>
  )
}
