import { useSelector } from 'react-redux'
import { AppState } from 'reducers/store'

interface PrimeContractCounts {
  /** APIの取得リクエスト中ステータス */
  isFetching: boolean
  /** プロジェクト登録数 */
  totalProjects: number
  /** プロジェクト上限数 */
  limitProjects: number
  /** プライムプロジェクト選択数 */
  totalSelectPrimeProjects: number
  /** プライムプロジェクト上限数 */
  limitPrimeProjects: number
}

/**
 * 企業ごとのプロジェクト登録数、プライムプロジェクト選択数、上限数をReduxストアから取得するカスタムHooks
 */
export const usePrimeContractCountsHook = (
  isGslite: boolean,
): PrimeContractCounts => {
  const state = useSelector((state: AppState) => state.primeContractCounts)

  const projectState = useSelector(
    (state: AppState) => state.primeServiceProject,
  )
  const a = projectState.initialSelectedProjectIds
  const b = projectState.selectedProjectIds
  const diff = calculateDiff(a, b)

  const projectCategory = isGslite ? 'liteProjects' : 'normalProjects'

  return {
    isFetching: state.isFetching,
    totalProjects: state[projectCategory].projectRegisteredCount!,
    limitProjects: state[projectCategory].projectLimitCount!,
    totalSelectPrimeProjects: state[projectCategory].primeSelectCount! + diff,
    limitPrimeProjects: state[projectCategory].primeLimitCount!,
  }
}

/**
 * 2つの配列の重複を取り除き、その要素の増減した数を取得する
 */
export const calculateDiff = (src: string[], target: string[]): number => {
  const duplicate = src.filter((s) => target.indexOf(s) !== -1)
  const minus = src.length - duplicate.length
  const plus = target.length - duplicate.length

  return plus - minus
}
