/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
  count: 3,
  companies: [
    {
      companyId: '0000000000',
      companyName: '株式会社テスト企業',
    },
    {
      companyId: '0000000001',
      companyName: '株式会社東陽町建設',
    },
    {
      companyId: '0000000002',
      companyName: '株式会社猪狩コンツェルン',
    },
  ],
}

const database = {
  count: 3,
  companies: [
    {
      companyId: '0000000000',
      companyName: '株式会社テスト企業',
    },
    {
      companyId: '0000000001',
      companyName: '株式会社東陽町建設',
    },
    {
      companyId: '0000000002',
      companyName: '株式会社猪狩コンツェルン',
    },
  ],
}

// ランダムデータ
for (let i = 0; i < 500; i++) {
  database.companies.push({
    companyId: Math.random().toString(32).substring(2),
    companyName: Math.random().toString(32).substring(2) + '株式会社',
  })
}

export const getCompanies = (companyId: string, companyName: string): any => {
  const companies = database.companies
    .filter((p) => {
      return partialSearch({ search: companyId, row: p, key: 'companyId' })
    })
    .filter((p) => {
      return partialSearch({ search: companyName, row: p, key: 'companyName' })
    })

  const companiesData = {
    count: companies.length,
    companies,
  }

  return companiesData
}
const partialSearch = ({
  search,
  row,
  key,
}: {
  search: any
  row: any
  [key: string]: any
}) => {
  if (search !== '') {
    return row[key].indexOf(search) > -1
  }

  return true
}
