import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { useAuth } from 'hooks/useAuth'
import LogoutButton from 'view/components/logout/LogoutButton'
import ManualLinkButton from 'view/components/button/ManualLinkButton'
import Grid from '@material-ui/core/Grid'
import { palette } from '../../../theme'
import { ManagerTypes } from '../../../constants'
import { ServiceLogo } from 'view/components/ServiceLogo'

interface IProps {
  display?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
)

/**
 * 以下のユーザ情報を表示するコンポーネント
 * 担当
 * 企業名 ユーザ姓 ユーザ名 さん
 */
const UserInfo = (): JSX.Element => {
  const { user } = useAuth()
  const typeName = ManagerTypes.find(
    (m) => m.type === user?.managerType,
  )?.typeName

  return (
    <Grid container item xs={12} direction="column" alignItems="flex-end">
      <Grid>
        <Typography
          variant="body2"
          style={{ marginTop: '10px', marginBottom: '0px', color: 'black' }}
          align="right"
          component="div"
        >
          <div>
            <Grid container item xs={12} alignItems="flex-end">
              <Grid item xs={9}>
                <span
                  style={{
                    color: 'white',
                    background: palette.primary.main,
                    padding: '0.2em 0.3em',
                    borderRadius: '5px',
                  }}
                >
                  {typeName}
                </span>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="primary" component="div">
                  元請企業
                </Typography>
              </Grid>
            </Grid>
          </div>
          {user?.companyName} {user?.lastName} {user?.firstName} さん
        </Typography>
      </Grid>
    </Grid>
  )
}

const Header = (props: IProps): JSX.Element => {
  const { display } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="primary"
        style={{ background: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <div style={{ marginTop: '10px', marginBottom: '0px' }}>
                <ServiceLogo />
              </div>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {display ? <UserInfo /> : null}
            </Grid>
          </Grid>
        </Toolbar>
        {display ? (
          <div
            style={{ backgroundColor: palette.primary.main, height: '40px' }}
          >
            <Grid
              container
              item
              xs={12}
              direction="row"
              justify="flex-end"
              style={{ marginTop: '5px' }}
            >
              <div style={{ marginRight: '4px' }}>
                <ManualLinkButton />
              </div>
              <div style={{ marginRight: '4px' }}>
                <LogoutButton />
              </div>
            </Grid>
          </div>
        ) : null}
      </AppBar>
    </div>
  )
}

export default Header
